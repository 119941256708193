import { useRef, useContext, useState } from 'react';
import styles from './AddGuestsComponent.module.css'

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import InputTextComponent from '../../keyboard/InputTextComponent';
import KeyboardComponent from '../../keyboard/KeyboardComponent';
import PopupBodyComponent from '../common/PopupBodyComponent';
import PopupBaseComponent from '../common/PopupBaseComponent';
import ClosePageButtonComponent from '../../commons/ClosePageButtonComponent'
import RightBodyComponent from '../common/RightBodyComponent';
import LeftBodyComponent from '../common/LeftBodyComponent';

import membersAdd from '../../../graphics/Popups/Icon Members Add.png'
import FooterButtonsComponent from '../common/FooterButtonsComponent';
import OutsideGlowComponent from '../common/OutsideGlowComponent';


const AddGuestComponent = () => {

    const inputRef = useRef()
    const keyboardRef = useRef()

    const [isOnFocus, setIsOnFocus] = useState(false)

    const { language, selectedProject, socketRef, appWidth, appHeight } = useContext(GlobalContext);
    const { setAddGuest } = useContext(ProjectDetailsContext);


    function handleEmailAdd(){
        socketRef.current.emit("CREATE_USER_AND_ADD_TO_PROJECT", { 
            projectId: selectedProject._id, 
            userData: {
                "emailString": inputRef.current.value,
                "role": 'GUEST'
            } 
        }, (callback) => {
            console.log("GUEST", callback)
        })
        setAddGuest(false)
    }

    
    return (
        <PopupBaseComponent>
            <KeyboardComponent visible ref={keyboardRef} x={(appWidth - 2140 - 60) / 2} y={appHeight * 0.6} width="2140px" height="610px" s="1" />
            <PopupBodyComponent style={{width: 2040, height: 680, top: "13%"}}>
                <ClosePageButtonComponent setClose={() => setAddGuest(false)} />
                <LeftBodyComponent givenWidth="800px" image={membersAdd} title="Add guest to project" description="Guests have limited access to the project and can participate in selected conferences."/>
                <RightBodyComponent>
                    <div className={styles.title}>New Guest</div>
                    <div className={styles.subTitle}>Email</div>
                    {isOnFocus && <OutsideGlowComponent style={{ top: 387, left: 1031, width: 1001 }}/>}
                    <InputTextComponent ref={inputRef} keyboardRef={keyboardRef} value="" placeHolder="Enter email here" styleType='POPUP' width= "960px" 
                    fontSize="40px" setIsOnFocus={setIsOnFocus}/>
                    {!isOnFocus && <FontAwesomeIcon className={styles.iconEdit} icon={byPrefixAndName.fal['pen']} />}
                    <div style={{ bottom: 120, position: "absolute" }}>
                        <FooterButtonsComponent lightText={"Create"} lightClick={handleEmailAdd} isOpaque={inputRef.current?.value === "" && !isOnFocus } darkClick={setAddGuest}/>
                    </div>
                </RightBodyComponent>
            </PopupBodyComponent>
            
        </PopupBaseComponent>
    );

}

export default AddGuestComponent;