import { useContext, useState } from 'react';
import styles from './ConferenceScreenComponent.module.css'
import SocketManagerConference from '../../utils/SocketManagerConference';
import WaitingForJoiningScreen from './WaitingForJoiningScreen';
import MediasoupConferenceComponent from './MediasoupConferenceComponent';
import TopicsComponent from './TopicsComponent';
import SideMenuComponent from './SideMenuComponent';
import { GlobalContext } from "../../contexts/globalContext";
import RegisterNewVisitor from './RegisterNewVisitor';
import PdfFileComponent from '../../files/PdfFileComponent';
import ImageFileComponent from '../../files/ImageFileComponent';
import VideoFileComponent from '../../files/VideoFileComponent';

const ConferenceScreenComponent = () => {
    const [waitingForJoining, setWaitingForJoining] = useState(true);
    const { currentParticipant, sharedFiles, openedFiles } = useContext(GlobalContext);

    const GetFileComponent = (file) => {
        console.log("GetFileComponent", file);
        switch (file.type) {
            case "pdf":
                return <PdfFileComponent file={file}></PdfFileComponent>
            case "image":
                return <ImageFileComponent file={file}></ImageFileComponent>
            case "video":
                return <VideoFileComponent file={file}></VideoFileComponent>
            default:
                return "";
        }

    }

    return (
        <div className={styles.component}>
            {currentParticipant && <SocketManagerConference></SocketManagerConference>}

            {waitingForJoining && !currentParticipant && <RegisterNewVisitor></RegisterNewVisitor>}

            {waitingForJoining && currentParticipant && <WaitingForJoiningScreen SetWaitingForJoining={setWaitingForJoining}></WaitingForJoiningScreen>}
            {!waitingForJoining && <MediasoupConferenceComponent SetWaitingForJoining={setWaitingForJoining} />}


            {!waitingForJoining && <TopicsComponent SetWaitingForJoining={setWaitingForJoining} />}

            {!waitingForJoining && <SideMenuComponent SetWaitingForJoining={setWaitingForJoining} />}

            {openedFiles.map((file) => (
                GetFileComponent(file)
            ))}
        </div>
    );
}

export default ConferenceScreenComponent;