import { useContext, useState, useEffect, useRef } from 'react';


import styles from './HeaderConferenceDetailsComponent.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons'

import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';



const HeaderConferenceDetailsComponent = ({  }) => {

    const { editConference, setEditConference } = useContext(ProjectDetailsContext);



    function handleCloseProject() {
        setEditConference()
    }


    return (
        <div style={{ flexDirection: "row", display: "flex" }}>
            <div style={{display: 'flex', flexDirection: 'row'}} onClick={handleCloseProject}>
                <FontAwesomeIcon className={styles.goBack} icon={byPrefixAndName.fal["arrow-left"]}/>
                <div className={styles.goBackText} >Back</div>
            </div>

            <div className={styles.titleCenterText} >{editConference.name}</div>

        </div>

    );

}

export default HeaderConferenceDetailsComponent;