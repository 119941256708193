import { useRef, useContext, useState } from 'react';
import styles from './ImageFileComponent.module.css'
import { GlobalContext } from "../contexts/globalContext"
import { MultitouchComponent } from 'use-gesture-pack';


const ImageFileComponent = ({file}) => {

    const imgRef = useRef(null);
    const { setSharedFiles, setOpenedFiles, appWidth, appHeight } = useContext(GlobalContext);
    const [isVisible, setIsVisible] = useState(false);    

    const startingHeight = 600

    const options = {
        appWidth, 
        appHeight,
		maxScale: appHeight / startingHeight,
        minScale: 0.5
	}

    const calculateWidth = () => {
        if (imgRef.current) {
            console.log("imgRef.current", imgRef.current);
            const containerHeight = startingHeight;
            const image = imgRef.current;
            const imageAspectRatio = image.height / image.width;
            const containerWidth = containerHeight / imageAspectRatio;

            console.log(image.height , image.width);
            console.log(startingHeight , containerWidth);

            return containerWidth;
        }

        return 0;
    };

    const containerStyle = {
        width: `${isVisible ? calculateWidth() : 60}px`,
        height: `${isVisible ? startingHeight : 75}px`,
        // opacity: `${isVisible ? 1 : 0}`,
        overflow: 'hidden',
        transition: `width 0.5s ease, height 0.5s ease, transform 0.5s ease`,
        borderRadius: '15px',
        padding: '7.5px',
        transformOrigin: 'center center',
        transform: `translate(-50%, -50%) matrix(1, 0, 0, 1, 0,0)`,
        backdropFilter: `${isVisible ? 'blur(10px)' : ''}`,
        backgroundColor: `${isVisible ? 'rgba(0, 0, 0, 0.3)' : 'transparent'}`
    };

    const onMinScaleReached = () => {
        setSharedFiles(files => files.filter(a => a.uid !== file.uid));
        setOpenedFiles(files => files.filter(a => a.uid !== file.uid));
    }

    return (
        <MultitouchComponent dragGesture={false} x={file.x + 75} y={file.y + 40} onMinScaleReached={onMinScaleReached} options={options}>
            <div className={`${styles.touch_component}  ${isVisible ? 'visible' : ''}`} style={containerStyle}>
                {/* <Lottie
                    loop
                    animationData={LoadingJson}
                    play
                    style={{ position:"absolute", left:"-5px", top:"-5px", width: 85, height: 100, opacity: `${isVisible ? 0 : 1}`  }}
                />
                <img style={{ position:"absolute", width: 60, height: 75, opacity: `${isVisible ? 0 : 1}`}} alt="icon" draggable="false" src={Icon} /> */}
                <img alt="imagine" ref={imgRef} style={{ borderRadius: "7.5px", width:"100%" }} onLoad={()=>{setIsVisible(true)}} draggable="false" src={process.env.REACT_APP_CMS_URL + file.path} />
            </div>
        </MultitouchComponent>
    );

}

export default ImageFileComponent;