import { useContext, useEffect, useRef, useState } from 'react';

import styles from './MultipleRoomsHourPickerComponent.module.css'

import { generateHourList, GetMaxZIndex } from '../../../utils/UtilitaryFunctions';

import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';
import { GlobalContext } from '../../../contexts/globalContext';
import { AddConferenceContext } from '../../../contexts/addConferenceContext';

import DarkButtonComponent from '../../commons/DarkButtonComponent';


const MultipleRoomsHourPickerComponent = ({ selectedHourStart, setSelectedHourStart, setSelectedHourEnd, duration, selectedHourEnd, selectedDate }) => {

    const ref = useRef()

    const hoursRef = useRef()
    const selectedHoursRef = useRef()
    const firstScrollHours = useRef(0)
    const numberToBeScrolledRef = useRef()

    const [hourList, setHourList] = useState([]);
    const [numberOfHoursPerHalf, setNumberOfHoursPerHalf] = useState(0)
    const [topPosition, setTopPosition] = useState(0);

    const { editConference } = useContext(ProjectDetailsContext)
    const { appWidth, appHeight } = useContext(GlobalContext)
    const { initialHourEndRef, initialHourStartRef, selectedHourRef, dataChanged, hasIntersection, selectedRooms, setSelectedRooms, dayConferences, room } = useContext(AddConferenceContext)


    const hourWidth = 204// 181.7 este latimea unui dreptunghi cu ora

    const numberHoursOnScreen = parseInt(appWidth / 2 / hourWidth)

    const oxDivider = window.innerWidth / appWidth;
    const oyDivider = window.innerHeight / appHeight;

    useEffect(() => {
        firstScrollHours.current = 0

        let margin = (appWidth / 2) % hourWidth
        hoursRef.current.style.marginLeft = `${margin}px`
        selectedHoursRef.current.style.marginLeft = `${margin}px`

        numberToBeScrolledRef.current = 12

        setNumberOfHoursPerHalf(numberHoursOnScreen)
        setHourList(generateHourList(numberHoursOnScreen))

    }, [])

    useEffect(() => {
        let [startHours, startMinutes] = selectedHourStart.split(':');
        let [endHours, endMinutes] = selectedHourEnd.split(':');

        let startDate = new Date();
        startDate.setHours(startHours, startMinutes, 0);

        let endDate = new Date();
        endDate.setHours(endHours, endMinutes, 0);

        let diffMs = endDate - startDate;

        const width = (diffMs / 3600000) * hourWidth;

        selectedHourRef.current.style.width = `${width}px`


        applyColorChangesSelectedHours()

        if (dataChanged) {
            hasIntersection.current = dayConferences.some(conference => {
                if (editConference) {
                    if (editConference._id === conference._id) return false
                }

                const conferenceStart = new Date(conference.scheduleDate);

                const conferenceStartToday = new Date();
                conferenceStartToday.setHours(conferenceStart.getHours(), conferenceStart.getMinutes(), 0, 0);

                const conferenceEnd = new Date(conferenceStartToday.getTime() + conference.duration * 60000);

                return (
                    (startDate < conferenceEnd && endDate > conferenceStartToday)
                );
            });

        }

    }, [selectedHourStart, selectedHourEnd, selectedDate])

    useEffect(() => {
        if (hasIntersection.current) {
            selectedHourRef.current.style.background = "red"
        } else {
            selectedHourRef.current.style.background = ""
        }
    }, [hasIntersection?.current])

    
    useEffect(() => {
        if (hoursRef.current) {
            const rect = hoursRef.current.getBoundingClientRect();
            setTopPosition(rect.y / oyDivider + 40)

            let hourDiff = 0

            if (editConference) {
                const scheduleDate = new Date(editConference.scheduleDate);
                const duration = editConference.duration;

                const endTime = new Date(scheduleDate.getTime() + duration * 60000);

                const twoHourMark = new Date(scheduleDate.getTime());
                twoHourMark.setHours(scheduleDate.getHours() + 4, 0, 0, 0);
                const threeHourMark = new Date(scheduleDate.getTime());
                threeHourMark.setHours(scheduleDate.getHours() + 5, 0, 0, 0);
                const forHourMark = new Date(scheduleDate.getTime());
                forHourMark.setHours(scheduleDate.getHours() + 6, 0, 0, 0);
                const fiveHourMark = new Date(scheduleDate.getTime());
                fiveHourMark.setHours(scheduleDate.getHours() + 7, 0, 0, 0);

                if (endTime > fiveHourMark) {
                    hourDiff = 4
                } else if (endTime > forHourMark) {
                    hourDiff = 3
                } else if (endTime > threeHourMark) {
                    hourDiff = 2
                } else if (endTime > twoHourMark) {
                    hourDiff = 1
                }
            }

            numberToBeScrolledRef.current = parseInt(selectedHourStart.split(':')[0]) + hourDiff

            hoursRef.current.scrollLeft = (parseInt(selectedHourStart.split(':')[0]) + hourDiff) * hourWidth
            selectedHoursRef.current.scrollLeft = (parseInt(selectedHourStart.split(':')[0]) + hourDiff) * hourWidth

        }
    }, [dayConferences]);


    function computeStartingHour(i) {

        let leftPosHour = selectedHourRef.current.getBoundingClientRect().left / oxDivider

        let addHour = 0

        if (leftPosHour < appWidth / 2 - 1 && leftPosHour >= appWidth / 2 - hourWidth) {
            addHour -= 1
        } else if (leftPosHour < appWidth / 2 - hourWidth && leftPosHour >= appWidth / 2 - 2 * hourWidth) {
            addHour -= 2
        } else if (leftPosHour < appWidth / 2 - 2 * hourWidth && leftPosHour >= appWidth / 2 - 3 * hourWidth) {
            addHour -= 3
        } else if (leftPosHour < appWidth / 2 - 3 * hourWidth) {
            addHour -= 4
        } else if (leftPosHour > appWidth / 2 + hourWidth && leftPosHour <= appWidth / 2 + 2 * hourWidth) {
            addHour += 1
        } else if (leftPosHour > appWidth / 2 + 2 * hourWidth && leftPosHour <= appWidth / 2 + 3 * hourWidth) {
            addHour += 2
        } else if (leftPosHour > appWidth / 2 + 3 * hourWidth) {
            addHour += 3
        }

        let hour = i + addHour

        if (hour < 10) hour = `0${hour}`
        else hour = `${hour}`

        initialHourStartRef.current = i + addHour

        return hour + ':' + selectedHourStart.split(':')[1]
    }

    function computeEndingHour(hourMinutes, i) {
        let [startHours, startMinutes] = hourMinutes.split(':').map(Number);
        let [durationHours, durationMinutes] = duration.split(':').map(Number);

        let endHours = startHours + durationHours;
        let endMinutes = startMinutes + durationMinutes;

        if (endMinutes >= 60) {
            endHours += Math.floor(endMinutes / 60);
            endMinutes = endMinutes % 60;
        }

        const formattedEndHours = String(endHours).padStart(2, '0');
        const formattedEndMinutes = String(endMinutes).padStart(2, '0');

        initialHourEndRef.current = i + 7

        return `${formattedEndHours}:${formattedEndMinutes}`
    }

    function computeScrollIntervalChange(i) {

        let hourMinutes = computeStartingHour(i)

        let hourMinutesEnd = computeEndingHour(hourMinutes, i)

        setSelectedHourStart(hourMinutes)
        setSelectedHourEnd(hourMinutesEnd)

        let [startHours, startMinutes] = hourMinutes.split(':');
        let [endHours, endMinutes] = hourMinutesEnd.split(':');

        let startDate = new Date();
        startDate.setHours(startHours, startMinutes, 0);

        let endDate = new Date();
        endDate.setHours(endHours, endMinutes, 0);

        hasIntersection.current = dayConferences.some(conference => {
            if (editConference) {
                if (editConference._id === conference._id) return false
            }

            const conferenceStart = new Date(conference.scheduleDate);

            const conferenceStartToday = new Date();
            conferenceStartToday.setHours(conferenceStart.getHours(), conferenceStart.getMinutes(), 0, 0);

            const conferenceEnd = new Date(conferenceStartToday.getTime() + conference.duration * 60000);

            return (
                (startDate < conferenceEnd && endDate > conferenceStartToday)
            );
        });

    }

    const handleScrollHour = () => {

        if (firstScrollHours.current > 1) {
            dataChanged.current = true
        }

        if (hoursRef.current) {
            const hours = hoursRef.current.children;

            if (selectedHoursRef.current) {
                selectedHoursRef.current.scrollLeft = hoursRef.current.scrollLeft;

                applyColorChangesSelectedHours()
            }

            let dayConferencesLength = 0

            for (let i = 0; i < dayConferences.length; i++) {
                if (dayConferences[i].room._id = room?._id) {
                    dayConferencesLength = dayConferences[i].conferences.length
                }
            }

            for (let i = dayConferencesLength; i < hours.length; i++) {
                const rect = hours[i].getBoundingClientRect();
                if (rect.left >= 0) {
                    computeScrollIntervalChange(i - dayConferencesLength)
                    break;
                }
            }
        }
        firstScrollHours.current = firstScrollHours.current + 1

    }

    function computeWidthDayConf(duration) {
        const width = duration / 60 * hourWidth;

        return width
    }

    function computePostionDayConf(scheduleDate) {

        let startDate = new Date(scheduleDate);

        let dayStart = new Date(scheduleDate);
        dayStart.setHours(0, 0, 0);

        let diffMs = startDate - dayStart;

        const position = (diffMs / 3600000) * hourWidth + 24 * hourWidth;

        return position
    }

    function applyColorChangesSelectedHours() {
        let cutLeft = selectedHourRef.current.getBoundingClientRect().left / oxDivider - (appWidth / 2) % hourWidth
        let cutRight = cutLeft + selectedHourRef.current.getBoundingClientRect().width / oxDivider

        selectedHoursRef.current.style.clipPath = `polygon(${cutLeft}px 0%, ${cutRight}px 0%, ${cutRight}px 100%, ${cutLeft}px 100%)`
    }

    const getNextDayFormatted = () => {
        const today = new Date();

        const nextDay = new Date(today);
        nextDay.setDate(today.getDate() + 1);

        const day = nextDay.getDate();
        const month = nextDay.toLocaleString('en-US', { month: 'short' });
        const year = nextDay.getFullYear();

        return `${day}.${month}.${year}`;
    };

    function selectRoom(element) {
        dataChanged.current = true
        let id = ""
        if(element.roomId !== undefined){
            id = element.roomId
        } else {
            id = element.room._id
        }

        
        setSelectedRooms((selectedRooms) => {
            if (!selectedRooms.includes(id)) {
                return [...selectedRooms, id]
            }
            return selectedRooms.filter((roomId) => roomId !== id);
        })
    }



    return (
        <div ref={ref} >
            <div ref={selectedHourRef} className={styles.selectedHourBigBox} style={{top: topPosition - 20}}>
                {dayConferences.map((element, index) => <div className={styles.selectedHour} key={index} style={{top: index * 170}}/>)}
            </div>
            

            {dayConferences.map((element, index) => <div key={index}>
                <div className={styles.roomName} 
                    style={{
                        top: topPosition + 170 * index,
                        zIndex: GetMaxZIndex() + 1
                    }}
                >{element.roomName}</div>
                <DarkButtonComponent style={{
                    position: "absolute",
                    right: 50,
                    top: topPosition + 170 * index - 10,
                    width: 238,
                    zIndex: GetMaxZIndex() + 1,
                    backgroundColor: selectedRooms.includes(element.roomId) ? "#33FFC8" : "",
                    color: selectedRooms.includes(element.roomId) ? "#060020" : ""
                }} onClick={() => selectRoom(element)}>
                    {selectedRooms.includes(element.roomId) ? "Deselect" : "Select"}
                </DarkButtonComponent>
            </div>)}



            <div ref={hoursRef} className={styles.hours} onScroll={handleScrollHour} style={{ height: 169 * dayConferences.length }}>
                {/* de pus pe nivelurile corespunzatoare */}
                {/* {dayConferences.length > 0 && dayConferences.map((element, index) => {
                    element.roomId === room?._id && element.conferences.map((conference, index) => <div className={styles.dayConferenceHour} key={index}
                        style={{
                            left: computePostionDayConf(conference.scheduleDate),
                            width: computeWidthDayConf(conference.duration),
                            opacity: conference?._id === editConference?._id ? "0" : "1"
                        }}
                    />)
                }
                )} */}
                {hourList.map((time, index) => {
                    const [hours, minutes] = time.split(':');
                    if (index >= numberOfHoursPerHalf && index < numberOfHoursPerHalf + 24) {
                        return (
                            <div className={styles.timeBigBox}>
                                {dayConferences.map((element, index) => <div className={styles.time} key={index} >
                                    {hours}
                                    <div className={styles.minutes}>:{minutes}</div>
                                </div>)}
                            </div>
                        )
                    } else if (index === numberOfHoursPerHalf + 24) {
                        return (
                            <div className={styles.timeBigBox}>
                                {dayConferences.map((element, index) => <div className={styles.time} key={index} style={{ opacity: 0.7 }}>
                                    <div className={styles.nextDay}>{getNextDayFormatted()}</div>
                                    {hours}
                                    <div className={styles.minutes}>:{minutes}</div>
                                </div>)}</div>
                        )
                    } else if (index > numberOfHoursPerHalf + 24) {
                        return (
                            <div className={styles.timeBigBox}>
                                {dayConferences.map((element, index) => <div className={styles.time} key={index} style={{ opacity: 0.7 }}>
                                    {hours}
                                    <div className={styles.minutes}>:{minutes}</div>
                                </div>)}</div>
                        )
                    } else {
                        return (
                            <div className={styles.timeBigBox}>
                                {dayConferences.map((element, index) => <div className={styles.time} key={index} style={{ opacity: 0 }}>
                                    {hours}
                                    <div className={styles.minutes}>:{minutes}</div>
                                </div>)}</div>
                        )
                    }

                })}

            </div>

            <div ref={selectedHoursRef} className={styles.selectedHours}
                style={{
                    top: hoursRef.current ? hoursRef.current?.getBoundingClientRect().y / oyDivider : ""
                }}>
                {hourList.map((time, index) => {
                    const [hours, minutes] = time.split(':');
                    if (index >= numberOfHoursPerHalf) {
                        return (
                            <div className={styles.timeBigBox}>
                                {dayConferences.map((element, index) => <div className={styles.time} key={index}>
                                    {hours}
                                    <div className={styles.minutes}>:{minutes}</div>
                                </div>)}</div>
                        )
                    } else {
                        return (
                            <div className={styles.timeBigBox}>
                                {dayConferences.map((element, index) => <div className={styles.time} key={index} style={{ opacity: 0 }}>
                                    {hours}
                                    <div className={styles.minutes}>:{minutes}</div>
                                </div>)}</div>
                        )
                    }
                })}
            </div>

        </div>
    );

}

export default MultipleRoomsHourPickerComponent;