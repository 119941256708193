import styles from './DetailsCardComponent.module.css'

import titleSvg from '../../../graphics/linie titlu.svg'



const DetailsCardComponent = ({title, height, children, indexSelected}) => {

    return (
        <div className={styles.component} >
            <div className={styles.title}>
                <img className={styles.titleGraphic} alt="icon" src={titleSvg} />
                {title.toUpperCase()} 
            </div>
            {children.length ? children.map((child, index) => {
                if(Array.isArray(child)){
                    return child.map((kid, i) => <div className={styles.card} key={`${index}${i}`} 
                        style={{marginTop: index === 0 && i === 0 ? -60 : 0, border: i === indexSelected ? "4px solid #77EDDC" : "0px"}}
                    >
                        {kid}
                    </div>)
                } 
                return <div className={styles.card} key={index} style={{marginTop: index === 0 ? -60 : 0}}>{child}</div>
                }) 
                : 
                <div className={styles.card} style={{marginTop: -60, height}}>{children}</div>
            }

        </div>
    );

}

export default DetailsCardComponent;