import { useContext, useState, useEffect } from 'react';

import { GlobalContext } from "../../../contexts/globalContext";

import PD_BaseListComponent from '../PD_BaseListComponent';
import PD_ConferenceComponent from './PD_ConferenceComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons'

import DarkButtonComponent from '../../commons/DarkButtonComponent';
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';



const ConferenceListComponent = () => {

    const { language, selectedProject, setAddParticipants, currentUser } = useContext(GlobalContext);
    const { setCreateConference, setCreateMeeting } = useContext(ProjectDetailsContext)


    const [sortedConferences, setSortedConferences] = useState(selectedProject.conferences.sort((a, b) => new Date(b.scheduleDate) - new Date(a.scheduleDate)))

    useEffect(() => {
        setSortedConferences(selectedProject.conferences.sort((a, b) => new Date(b.scheduleDate) - new Date(a.scheduleDate)))
    }, [selectedProject.conferences])

    const meetingStyle = {
        border: "2px solid #33FFC8",
        borderRadius: "8px",
        backdropFilter: "blur(31px)",
        width: "540px",
        height: "78px",
        marginLeft: -57,
        marginBottom: 57
    }

    const confStyle = {
        border: "2px solid #33FFC8",
        borderRadius: "8px",
        backdropFilter: "blur(31px)",
        width: "540px",
        height: "78px",
        marginLeft: 28,
        marginBottom: 57
    }

    return (

        <PD_BaseListComponent
            title={language.project_details.Conferences}
            list={sortedConferences?.map((conference) => (
                <PD_ConferenceComponent key={conference._id} conference={conference} />
            ))}
            button={
                <div>
                    {currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator" &&
                        <DarkButtonComponent onClick={() => setCreateConference(true)} style={meetingStyle}>
                            {/* <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['keynote']} /> */}
                            Add conference
                        </DarkButtonComponent>
                    }
                    {currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator" &&<DarkButtonComponent onClick={() => console.log("meeting")} style={confStyle}>
                        {/* <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['users-rectangle']} /> */}
                        Add meeting
                    </DarkButtonComponent>}
                </div>

            }
        />

    );

}

export default ConferenceListComponent;