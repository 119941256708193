import { useRef, useContext, useEffect, useState } from 'react';

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from "../../../contexts/projectDetailsContext";
import { AddConferenceContext } from "../../../contexts/addConferenceContext";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import styles from './TopicsDetailsConferenceComponents.module.css';

import DetailsCardComponent from './DetailsCardComponent';
import InitialsCircleComponent from '../../commons/InitialsCircleComponent';
import LightButtonComponent from '../../commons/LightButtonComponent';
import AddModeratorPopup from './AddModeratorPopup';
import DeleteNotificationComponent from '../common/DeleteNotificationComponent';

import AddTopicIcon from '../../../graphics/Popups/Icon Conferinta Add.png';

const TopicsDetailsConferenceComponents = () => {
    const [extended, setExtended] = useState(null);
    const [indexSelected, setIndexSelected] = useState(null);
    const [isDelete, setIsDelete] = useState(null);

    const { socketRef, currentUser } = useContext(GlobalContext);
    const { editConference, setEditConference } = useContext(ProjectDetailsContext);
    const {
        addNewTopic,
        selectedTopic,
        selectedModerator,
        addModerator,
        setAddModerator,
        setAddNewTopic,
        setAddNewSubtopic,
        setSelectedTopic,
        setOpenFiles,
        openFiles,
        setSelectedSubtopic,
    } = useContext(AddConferenceContext);

    const handleClickExtention = (id, index) => {
        setExtended((prev) => (prev === id ? null : id));
        setIndexSelected(index);
    };

    const handleClickOpenFiles = (e, topic) => {
        e.stopPropagation();
        setOpenFiles((prev) => (prev?._id === topic._id ? null : topic));
    };

    const handleClickAddSubtopic = (e, topic) => {
        e.stopPropagation();
        setSelectedSubtopic();
        setAddNewSubtopic(true);
        setSelectedTopic(topic);
    };

    const handleEditTopic = (e, topic) => {
        e.stopPropagation();
        setAddNewTopic(true);
        setSelectedTopic(topic);
    };

    const handleEditSubtopic = (e, topic, subtopic) => {
        e.stopPropagation();
        setSelectedSubtopic(subtopic);
        setAddNewSubtopic(true);
        setSelectedTopic(topic);
    };

    const handleUpdateModerator = (e, topic) => {
        if (currentUser?.role.toLowerCase() === "administrator") {
            e.stopPropagation();
            setAddModerator(true);
            setSelectedTopic(topic);
        }
    };

    const handleDeleteConfirmation = (type, item) => {
        setIsDelete({
            type, // 'topic' or 'subtopic'
            item, // Item object containing relevant details
        });
    };

    const confirmDelete = () => {
        if (isDelete?.type === 'topic') {
            socketRef.current.emit("CONFERENCE_DELETE_TOPIC", isDelete.item._id, editConference._id, (callback) => {
                if (!callback.error) {
                    setEditConference((prev) => ({
                        ...prev,
                        topics: callback.response,
                    }));
                    setSelectedTopic();
                    setOpenFiles()
                }
            });
        } else if (isDelete?.type === 'subtopic') {
            socketRef.current.emit("CONFERENCE_DELETE_SUBTOPIC", isDelete.item._id, selectedTopic._id, (callback) => {
                if (!callback.error) {
                    let changedTopic = {
                        ...selectedTopic,
                        subtopics: callback.response,
                    };
                    setEditConference((prev) => ({
                        ...prev,
                        topics: prev.topics.map((topic) =>
                            topic._id === selectedTopic._id ? changedTopic : topic
                        ),
                    }));
                }
            });
        }
        setIsDelete(null);
    };

    const handleDeleteTopic = (e, topicId) => {
        e?.stopPropagation();
        const topic = editConference.topics.find((t) => t._id === topicId);
        handleDeleteConfirmation('topic', topic);
    };

    const handleDeleteSubtopic = (e, topic, subtopicId) => {
        e?.stopPropagation();
        const subtopic = topic.subtopics.find((st) => st._id === subtopicId);
        handleDeleteConfirmation('subtopic', subtopic);
    };

    useEffect(() => {
        if (selectedModerator && selectedTopic && !addNewTopic) {
            socketRef.current.emit('CONFERENCE_UPDATE_TOPIC', {
                conferenceId: editConference._id,
                topicId: selectedTopic,
                newData: {
                    moderator: selectedModerator._id,
                },
            }, callback => {
                if (!callback.error) {
                    setEditConference((prev) => ({
                        ...prev,
                        moderators: callback.response.moderators,
                        topics: prev.topics.map((topic) =>
                            topic._id === selectedTopic._id ? callback.response.updatedTopic : topic
                        ),
                    }));
                }
            });
        }
    }, [selectedModerator]);

    return (
        <>
            {editConference.topics.length > 0 ? (
                <DetailsCardComponent title="Topics" indexSelected={indexSelected}>
                    {currentUser?.role.toLowerCase() === "administrator" && (
                        <div style={{ width: "100%", height: 30 }}>
                            <LightButtonComponent
                                onClick={() => { setAddNewTopic(true); setSelectedTopic(); }}
                                style={{
                                    width: 440,
                                    background: "transparent linear-gradient(90deg, #77EDDC 0%, #DBFD95 97%, #DEFE93 100%) 0% 0% no-repeat padding-box",
                                    marginLeft: 77,
                                    alignItems: 'center',
                                    position: "absolute",
                                    top: 35,
                                }}
                            >
                                <FontAwesomeIcon className={styles.iconPlus} icon={byPrefixAndName.fas['plus']} /> New topic
                            </LightButtonComponent>
                        </div>
                    )}
                    {editConference.topics.map((topic, index) => (
                        <div key={topic._id} style={{ width: "100%" }} onClick={() => handleClickExtention(topic._id, index)}>
                            <div className={styles.notExtended}>
                                <div className={styles.index}>{index < 9 ? `0${index + 1}` : index + 1}.</div>
                                <div
                                    style={{ color: openFiles?._id === topic._id ? "#33FFC8" : "" }}
                                    onClick={(e) => {
                                        if (currentUser?.role.toLowerCase() === "administrator" || currentUser._id === topic.moderator?._id)
                                            handleClickOpenFiles(e, topic);
                                    }}
                                >
                                    {topic.name}
                                </div>
                                {openFiles?._id === topic._id && (
                                    <>
                                        <FontAwesomeIcon
                                            className={styles.iconButton}
                                            style={{ right: currentUser?.role.toLowerCase() === "administrator" ? 140 : 60 }}
                                            icon={byPrefixAndName.fal['pen']}
                                            onClick={(e) => handleEditTopic(e, topic)}
                                        />
                                        {currentUser?.role.toLowerCase() === "administrator" && (
                                            <FontAwesomeIcon
                                                className={styles.iconButton}
                                                style={{ right: 60 }}
                                                icon={byPrefixAndName.fal['trash']}
                                                onClick={(e) => handleDeleteTopic(e, topic._id)}
                                            />
                                        )}
                                    </>
                                )}
                                {(currentUser?.role.toLowerCase() === "administrator" || currentUser._id === topic.moderator?._id) && (
                                    <FontAwesomeIcon
                                        className={styles.iconButton}
                                        style={{ color: openFiles?._id === topic._id ? "#33FFC8" : "" }}
                                        icon={byPrefixAndName.fas['chevron-right']}
                                        onClick={(e) => handleClickOpenFiles(e, topic)}
                                    />
                                )}
                                <div className={styles.details} style={{ color: topic.moderator?._id === currentUser._id ? "#FFFFFF" : "" }}>
                                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fal['bookmark']} />{topic.subtopics?.length}
                                    <div
                                        className={styles.moderatorInfo}
                                        style={{ color: topic.moderator?._id === currentUser._id ? "#33FFC8" : "" }}
                                    >
                                        <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fal['microphone-stand']} />
                                        {topic.moderator.name}
                                    </div>
                                </div>
                            </div>
                            {extended === topic._id && (
                                (currentUser?.role.toLowerCase() === "administrator" || currentUser._id === topic.moderator?._id) && (
                                    <div className={styles.extended}>
                                        {/* Subtopics rendering logic */}
                                    </div>
                                )
                            )}
                            {isDelete?.type === 'topic' && isDelete.item._id === topic._id && (
                                <DeleteNotificationComponent
                                    name="Topic"
                                    funct={confirmDelete}
                                    onClose={() => setIsDelete(null)}
                                />
                            )}
                        </div>
                    ))}

                </DetailsCardComponent>
            ) : (
                <DetailsCardComponent title="Topics">
                    {currentUser?.role.toLowerCase() === "administrator" ? (
                        <div style={{ width: 2015, height: 1261, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className={styles.centered}>
                                <img src={AddTopicIcon} alt="icon add" className={styles.imgAdd} onClick={() => setAddNewTopic(true)} />
                                <label className={styles.labelCreate}>Create new topic</label>
                                <label className={styles.labelTextBold}>
                                    Provide a topic name and assign a moderator.
                                    <br /> You can assist by adding optional details like subtopics and files.
                                </label>
                                <LightButtonComponent
                                    onClick={() => setAddNewTopic(true)}
                                    style={{
                                        width: 440,
                                        background: "transparent linear-gradient(90deg, #77EDDC 0%, #DBFD95 97%, #DEFE93 100%) 0% 0% no-repeat padding-box",
                                        position: "relative",
                                        marginTop: 31,
                                    }}
                                >
                                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['plus']} /> Add topic
                                </LightButtonComponent>
                            </div>
                        </div>
                    ) : (
                        <div style={{ width: "100%" }}> No topics added yet.</div>
                    )}
                </DetailsCardComponent>
            )}
            {addModerator && !addNewTopic && <AddModeratorPopup />}

        </>
    );
};

export default TopicsDetailsConferenceComponents;
