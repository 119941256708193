import { useRef, useContext, useState } from 'react';
import styles from './AddPartnersComponent.module.css'

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import InputTextComponent from '../../keyboard/InputTextComponent';
import PopupBodyComponent from '../common/PopupBodyComponent';
import PopupBaseComponent from '../common/PopupBaseComponent';
import ClosePageButtonComponent from '../../commons/ClosePageButtonComponent'
import RightBodyComponent from '../common/RightBodyComponent';
import LeftBodyComponent from '../common/LeftBodyComponent';

import membersAdd from '../../../graphics/Popups/Icon Members Add.png'
import FooterButtonsComponent from '../common/FooterButtonsComponent';
import OutsideGlowComponent from '../common/OutsideGlowComponent';
import KeyboardComponent from '../../keyboard/KeyboardComponent';


const AddPartnersComponent = () => {

    const inputRefName = useRef()
    const inputRefEmail = useRef()
    const inputRefPass = useRef()
    const inputRefPassConf = useRef()
    const keyboardRef = useRef()

    const [isOnFocusName, setIsOnFocusName] = useState(false)
    const [isOnFocusEmail, setIsOnFocusEmail] = useState(false)
    const [isOnFocusPass, setIsOnFocusPass] = useState(false)
    const [isOnFocusPassConf, setIsOnFocusPassConf] = useState(false)

    const { language, selectedProject, socketRef, appHeight, appWidth, setAlertMessage } = useContext(GlobalContext);
    const { setAddPartners } = useContext(ProjectDetailsContext);

    const allFieldsCompleted = () => {
        const name = inputRefName.current?.value?.trim() || "";
        const email = inputRefEmail.current?.value?.trim() || "";
        const password = inputRefPass.current?.value?.trim() || "";
        const confirmPassword = inputRefPassConf.current?.value?.trim() || "";

        return (
            name.length > 0 &&
            email.length > 0 &&
            password.length > 0 &&
            confirmPassword.length > 0
        );
    };

    const handleEmailAdd = () => {
        if (inputRefPass.current.value === inputRefPassConf.current.value) {
            socketRef.current.emit("CREATE_USER_AND_ADD_TO_PROJECT", {
                projectId: selectedProject._id,
                userData: {
                    "name": inputRefName.current.value,
                    "email": inputRefEmail.current.value,
                    "password": inputRefPass.current.value,
                    "role": "PARTNER",
                }
            }, (callback) => {
                console.log("PARTNER", callback);
                setAddPartners(false);
            });
        } else {
            setAlertMessage({ type: "ERROR", message: "Passwords do not match. Please ensure password and Confirm Password fields are identical." });
        }
    }

    return (
        <PopupBaseComponent>
            <KeyboardComponent visible ref={keyboardRef} x={(appWidth - 2140 - 60) / 2} y={appHeight * 0.6} width="2140px" height="610px" s="1" />
            <PopupBodyComponent style={{ width: 2688, height: 740, top: "13%" }}>
                <ClosePageButtonComponent setClose={() => setAddPartners(false)} />
                <LeftBodyComponent givenWidth="800px" image={membersAdd} title="Add partner to project" description="Create and add a new partner." />
                <RightBodyComponent>
                    <div className={styles.title}>New Partner</div>

                    <div className={styles.bodyDetails}>
                        <div className={styles.column}>
                            <div className={styles.subTitle}>Name</div>
                            {isOnFocusName && <OutsideGlowComponent style={{ top: 97, left: 11, width: 801 }} />}
                            <InputTextComponent ref={inputRefName} keyboardRef={keyboardRef} value=""
                                placeHolder="Enter name here" styleType='POPUP' width="760px" fontSize="40px" setIsOnFocus={setIsOnFocusName} />
                            {!isOnFocusName && <FontAwesomeIcon className={styles.iconEdit} icon={byPrefixAndName.fal['pen']} />}

                            <div className={styles.subTitle}>Password</div>
                            {isOnFocusPass && <OutsideGlowComponent style={{ top: 289, left: 11, width: 801 }} />}
                            <InputTextComponent type="password" ref={inputRefPass} keyboardRef={keyboardRef} value="" placeHolder="Enter password here"
                               styleType='POPUP' width="760px" fontSize="40px" setIsOnFocus={setIsOnFocusPass} />
                            {!isOnFocusPass && <FontAwesomeIcon className={styles.iconEdit} style={{ top: 193 }} icon={byPrefixAndName.fal['pen']} />}

                        </div>

                        <div className={styles.column} style={{ paddingLeft: 40, position: "fixed", left: "calc(66%)" }}>
                            <div className={styles.subTitle}>Email</div>
                            {isOnFocusEmail && <OutsideGlowComponent style={{ top: 97, left: 31, width: 801 }} />}
                            <InputTextComponent ref={inputRefEmail} keyboardRef={keyboardRef} value="" placeHolder="Enter email here"
                                styleType='POPUP' width="760px" fontSize="40px" setIsOnFocus={setIsOnFocusEmail} />
                            {!isOnFocusEmail && <FontAwesomeIcon className={styles.iconEdit} style={{ left: 775 }} icon={byPrefixAndName.fal['pen']} />}

                            <div className={styles.subTitle}>Confirm Password</div>
                            {isOnFocusPassConf && <OutsideGlowComponent style={{ top: 289, left: 31, width: 801 }} />}
                            <InputTextComponent type="password" ref={inputRefPassConf} keyboardRef={keyboardRef} value="" placeHolder="Re-enter password here"
                                styleType='POPUP' width="760px" fontSize="40px" setIsOnFocus={setIsOnFocusPassConf} />
                            {!isOnFocusPassConf && <FontAwesomeIcon className={styles.iconEdit} style={{ top: 193, left: 775 }} icon={byPrefixAndName.fal['pen']} />}

                        </div>
                    </div>
                    <div className={styles.explanation}>⚠️ All fields are mandatory</div>
                    <div style={{ marginLeft: 20 }}>
                        <FooterButtonsComponent lightText={"Create"} isOpaque={!allFieldsCompleted()} lightClick={handleEmailAdd} darkClick={setAddPartners} />
                    </div>

                    
                </RightBodyComponent>
            </PopupBodyComponent>
        </PopupBaseComponent>
    );
}

export default AddPartnersComponent;
