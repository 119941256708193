import { useRef, useContext, useState, useEffect } from 'react';

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';

import styles from './ParticipantsListComponent.module.css'
import InputTextComponent from '../../keyboard/InputTextComponent';
import RightBodyComponent from '../common/RightBodyComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons'
import LightButtonComponent from '../../commons/LightButtonComponent';

import InitialsCircleComponent from '../../commons/InitialsCircleComponent';
import { GetMaxZIndex } from '../../../utils/UtilitaryFunctions';


const ParticipantsListComponent = ({attachedParticipants, setAttachedParticipants, isOnFocus, setIsOnFocus}) => {

    const inputRef = useRef()
    const keyboardRef = useRef()
    const docsRef = useRef()
    const usersRef = useRef()

    const [currentRole, setCurrentRole] = useState("MEMBER")

    const { language, selectedProject, allUsers} = useContext(GlobalContext);
    const { setAddGuest, setAddMembers, setAddPartners, setAddVisitors } = useContext(ProjectDetailsContext);

    const [docs, setDocs] = useState()


    const optionsRoles = ["Member", "Partner", "Guest", "Visitor"]

    useEffect(() => {
        let users = allUsers.filter(user => !selectedProject.participants.some(participant => participant._id === user._id));
        usersRef.current = users

        let result = users.filter(doc => doc.role.includes("MEMBER"));
        let adminDocs = users.filter(doc => doc.role.toLowerCase().includes("administrator"));
        result = [...result, ...adminDocs];

        setDocs(result);
        docsRef.current = result
    }, [])

    
    useEffect(() => {
        console.log(attachedParticipants)

        let users = allUsers.filter(user => !selectedProject.participants.some(participant => participant._id === user._id));
        usersRef.current = users.filter(user => !attachedParticipants.some(participant => participant._id === user._id))

        handleChangeRole(currentRole)

    },[attachedParticipants])


    function onTextChanged() {
        let result = []
        if (inputRef.current.value) {
            result = docsRef.current.filter(doc => doc.name?.toLowerCase().includes(inputRef.current.value.toLowerCase()));
        } else {
            result = docsRef.current
        }
        setDocs(result)
    }


    function openPopup(){
        if(currentRole.toLowerCase() === "administrator" || currentRole.toLowerCase() === "member"){
            setAddMembers(true)
        } else if(currentRole.toLowerCase() === "partner"){
            setAddPartners(true)
        } else if(currentRole.toLowerCase() === "guest"){
            setAddGuest(true)
        } else if(currentRole.toLowerCase() === "visitor"){
            setAddVisitors(true)
        }
    }

    useEffect(() => {
        let result = usersRef.current.filter(doc => doc.role.includes(currentRole));

        if(currentRole === "MEMBER") {
            let adminDocs = usersRef.current.filter(doc => doc.role.toLowerCase().includes("administrator"));
            result = [...result, ...adminDocs];
        }

        inputRef.current.value = ""
        setDocs(result);
        docsRef.current = result
    },[currentRole])

    const handleChangeRole = (change) => {
        setCurrentRole(change)

        let result = usersRef.current.filter(doc => doc.role.includes(change));

        if(change === "MEMBER") {
            let adminDocs = usersRef.current.filter(doc => doc.role.toLowerCase().includes("administrator"));
            result = [...result, ...adminDocs];
        }

        inputRef.current.value = ""
        setDocs(result);
        docsRef.current = result
    }

    const handleClickUser = (user) => {
        setAttachedParticipants((participants) => [...participants, user])
    }


    return (
        <RightBodyComponent paddingLeft={60}>
            <div className={styles.tablePart}>
                <div className={styles.selectRole}>
                    {optionsRoles.map((option, index) => 
                    <div key={index} className={currentRole === option.toUpperCase() ? styles.selectedTag : styles.tag} onClick={() => handleChangeRole(option.toUpperCase())}>
                        {option}s
                    </div>)}
                </div>
                <div className={styles.select_options}>
                    <InputTextComponent ref={inputRef} fontSize="30" keyboardRef={keyboardRef} value="" width="785px" setIsOnFocus={setIsOnFocus} styleType='SEARCHPOPUP' placeHolder={language.add_participants.Search} onTextChanged={onTextChanged}/>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fal['magnifying-glass']} style={{color: isOnFocus ? "#33FFC8" : ""}}/>
                </div> 
                <div className={styles.participantsList}>
                    {docs?.map((participant) => <div key={participant._id} className={styles.participantCard} onClick={() => handleClickUser(participant)}>
                        <InitialsCircleComponent name={participant.name} style={{ marginLeft: 25, height: 121, width: 121, 
                            border: "6px solid #0D0032", marginBottom: 10, font: 'normal normal normal 54px / 95px "Bio Sans"' }} />
                        <div className={styles.participantDetails}>
                            <div style={{letterSpacing: "2.2px"}}>{participant.role}</div>
                            <div className={styles.participantName}>{participant.name}</div>
                            {participant.function?.name} {participant.function ? "|" : ""} {participant.department?.name}
                        </div>
                    </div>)}
                </div>
                <div className={styles.participantListShadowUp} style={{ zIndex: GetMaxZIndex() + 1, pointerEvents: 'none' }} />
                <div className={styles.participantListShadow} style={{ zIndex: GetMaxZIndex() + 1, pointerEvents: 'none' }} />
                <LightButtonComponent onClick={() => openPopup()} style={{width: "480px", bottom: 80, position: "absolute", borderRadius: 8, marginLeft: 0}}>
                    <FontAwesomeIcon className={styles.addIcon} icon={byPrefixAndName.fas['user-plus']}/>
                    New {currentRole.charAt(0).toUpperCase() + currentRole.slice(1).toLowerCase()}
                </LightButtonComponent>
            </div>
            
            {/* <FooterButtonsComponent lightText={"Add"} isOpaque={!isCheck.length > 0} lightClick={handleParticipantsAdd} darkClick={setAddParticipants}/> */}
        </RightBodyComponent>

    );

}

export default ParticipantsListComponent;