import { useRef, useContext, useState, useEffect } from 'react';
import styles from './NotAttachedParticipantsComponent.module.css'

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';
import { AddConferenceContext } from '../../../contexts/addConferenceContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import bigIcon from '../../../graphics/Popups/img-arrange-part.png'
import InputTextComponent from '../../keyboard/InputTextComponent';
import InitialsCircleComponent from '../../commons/InitialsCircleComponent';
import { GetMaxZIndex } from '../../../utils/UtilitaryFunctions';
import DarkButtonComponent from '../../commons/DarkButtonComponent';
import KeyboardComponent from '../../keyboard/KeyboardComponent';

import { getUpcomingConferences } from '../../../utils/UtilitaryFunctions'

const NotAttachedParticipantsComponent = ({ displayedRoom, isRemote, allDisplayedDesks, displayedParticipants, setDisplayedParticipants,
    attachedParticipants, setAttachedParticipants, allParticipantsRef}) => {

    const inputRef = useRef()
    const keyboardRef = useRef()
    const participantsFilterSearchRef = useRef()

    const [selectedFilter, setSelectedFilter] = useState("All")
    const [isOnFocus, setIsOnFocus] = useState(false)


    const { language, appWidth, socketRef, appHeight, currentUser, setNotificationUpcomingConferences } = useContext(GlobalContext);
    const {  } = useContext(ProjectDetailsContext)
    const { conferenceDetailsForParticipants, setAddVisitor } = useContext(AddConferenceContext);

    const filterOptions = [
        "All", "Members", "Guests", "Partners"
    ]

    useEffect(() => {
        if (allParticipantsRef.current) {
            let result = []
            if (inputRef.current.value) {
                result = allParticipantsRef.current.filter(participant => participant.name?.toLowerCase().includes(inputRef.current.value.toLowerCase()));
            } else {
                result = allParticipantsRef.current
            }

            let value = selectedFilter
            value = value.toUpperCase().replace(/S$/, '');

            if (value !== "ALL") {
                if (value === "MEMBER")
                    result = result.filter(participant => participant.role.includes(value) || participant.role.includes('ADMINISTRATOR'));
                else
                    result = result.filter(participant => participant.role.includes(value));
            }
            setDisplayedParticipants(result)
        }
    }, [inputRef.current?.value])


    const handleFilterChange = (e) => {

        let value = e.target.innerText
        value = value.toUpperCase().replace(/S$/, '');

        let result = []
        if (value !== "ALL") {
            if (value === "MEMBER")
                result = allParticipantsRef.current.filter(participant => participant.role.includes(value) || participant.role.includes('ADMINISTRATOR'));
            else
                result = allParticipantsRef.current.filter(participant => participant.role.includes(value));

        } else {
            result = allParticipantsRef.current
        }

        if (inputRef.current.value) {
            result = result.filter(participant => participant.name?.toLowerCase().includes(inputRef.current.value.toLowerCase()));
        } else {
            result = result
        }

        setSelectedFilter(e.target.innerText)
        setDisplayedParticipants(result);


    }

    function onTextChanged() {
        setDisplayedParticipants([])
    }


    const addParticipantToRoom = (selectedParticipant) => {

        const availableDesk = allDisplayedDesks.find(
            (desk) => !attachedParticipants.some(attached => attached.desk._id === desk._id)
        );

        socketRef.current.emit("CONFERENCE_ADD_PARTICIPANT_TO_DESK", {
            conferenceId: conferenceDetailsForParticipants._id,
            participantId: selectedParticipant._id,
            deskId: availableDesk._id,
            roomId: displayedRoom._id
        }, callback => {
            // console.log(callback)
            if(!callback.error){
                setAttachedParticipants(callback.response)
                setDisplayedParticipants((prevParticipants) =>
                    prevParticipants.filter((participant) => participant._id !== selectedParticipant._id)
                );

                allParticipantsRef.current = allParticipantsRef.current.filter((participant) => participant._id !== selectedParticipant._id)
                getUpcomingConferences(socketRef, currentUser._id, setNotificationUpcomingConferences)
            }
            
        })

    }

    const addRemoteParticipant = (selectedParticipant) => {
        socketRef.current.emit("CONFERENCE_ADD_REMOTE_PARTICIPANT", {
            conferenceId: conferenceDetailsForParticipants._id,
            participantId: selectedParticipant._id,
        }, callback => {
            if (!callback.error) {
                setAttachedParticipants(callback.response)
                setDisplayedParticipants((prevParticipants) =>
                    prevParticipants.filter((participant) => participant._id !== selectedParticipant._id)
                );
                allParticipantsRef.current = allParticipantsRef.current.filter((participant) => participant._id !== selectedParticipant._id)
                getUpcomingConferences(socketRef, currentUser._id, setNotificationUpcomingConferences)
            }
        })

    }


    return (
        <div className={styles.leftBody}>
            {isOnFocus && <KeyboardComponent visible ref={keyboardRef} x={(appWidth - 2140 - 60) / 2} y={appHeight * 2 / 3} width="2140px" height="610px" s="1"/>}
            <div className={styles.info}>
                <img src={bigIcon} alt={"img"} className={styles.img} />
                <div className={styles.title}>Arrange Participants</div>
                <div className={styles.description}>
                    Arrange participants in the list according to their assigned spots in the rooms. <br></br><br></br>
                    You can also add remote users as needed.
                </div>
            </div>
            <div className={styles.participantsFilterSearch} ref={participantsFilterSearchRef}>
                <div className={styles.filter}>
                    {filterOptions.map((option, index) => <div key={index} className={selectedFilter === option ? styles.selectedTag : styles.tag} onClick={handleFilterChange}>
                        {option}
                    </div>)}
                </div>
                <InputTextComponent ref={inputRef} fontSize="30" width={appWidth / 7} marginLeft="25px" keyboardRef={keyboardRef} value="" styleType='SEARCHPOPUP'
                    placeHolder={language.add_participants.Search} onTextChanged={onTextChanged} setIsOnFocus={setIsOnFocus}/>
                <FontAwesomeIcon className={styles.iconMagnifyingGlass} style={{color: isOnFocus ? "#33FFC8" : ""}} icon={byPrefixAndName.fal['magnifying-glass']} />

            </div>
            <div className={styles.participantsListAndAdd}>
                <div className={styles.participantsList}>
                    {displayedParticipants?.map((participant) => <div key={participant._id} className={styles.participantCard} onClick={() => {
                        if (isRemote)
                            addRemoteParticipant(participant)
                        else
                            addParticipantToRoom(participant)
                    }}>
                        <InitialsCircleComponent name={participant.name} style={{ marginLeft: 25, height: 121, width: 121, border: "6px solid #0D0032", marginBottom: 10 }} />
                        <div className={styles.participantDetails}>
                            <div style={{letterSpacing: "2.2px"}}>{participant.role}</div>
                            <div className={styles.participantName}>{participant.name}</div>
                            {participant.function?.name} {participant.function ? "|" : ""} {participant.department?.name}
                        </div>
                    </div>)}

                </div>
                <div className={styles.participantListShadow} style={{ zIndex: GetMaxZIndex() + 1, pointerEvents: 'none' }} />
                <DarkButtonComponent style={{ backgroundColor: "#2D2748", position: "relative", marginBottom: 40, width: 456 }} onClick={()=>setAddVisitor(true)}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['plus']} /> Add Visitor
                </DarkButtonComponent>
            </div>
        </div>

    );

}

export default NotAttachedParticipantsComponent;