import { useEffect, useContext, useState } from 'react';

import { GlobalContext } from "../../contexts/globalContext";
import TopicDetailsComponent from "./TopicDetailsComponent"

const TopicComponent = ({ topic, index }) => {

    const { currentTopic, currentParticipant, conferenceSocketRef, currentConference } = useContext(GlobalContext);
    const [topicNumber, setTopicNumber] = useState("01");

    useEffect(() => {
        if (index < 10) {
            setTopicNumber("0" + index);
        } else {
            setTopicNumber("" + index);
        }
    }, [])

    const GoToNextTopic = () => {
        conferenceSocketRef.current.emit("GO_TO_NEXT_TOPIC", currentConference._id);
    }

    return (

        <div style={{ display: "flex", flexDirection: "column", width: "auto", height: "auto", marginBottom: "10px", marginTop:"5px", borderRadius: "10px", overflow:"hidden" }}>
            <div style={{ borderRadius: (topic.moderator._id == currentParticipant ? "10px" : "10px 10px 0px 0px"), display: "flex", padding: "0px", margin: "0px 0px", color: "white", width: "900px", height: "100%", backgroundColor: (topic._id == currentTopic?._id ? "#292148" : "#161032"), fontSize: "30px", overflow: "hidden" }}>
                {currentTopic?._id == topic._id &&
                    <>
                        <div style={{ height: "auto", padding: "0px 20px", color: "#33FFC8", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#423467", font: "normal normal bold 74px/126px 'Bio Sans'" }}>{topicNumber}</div>
                        <div style={{ width: "100%", marginLeft: "20px" }}>
                            <div style={{ font: "normal normal 600 70px/74px 'Bio Sans'", color: "#33FFC8", padding: "40px 0px" }}>{topic.name}</div>
                            <div style={{ font: "normal normal normal 32px/54px 'Bio Sans'", color: "#EEEEEE", position:"relative" }}>
                                <i className="fa-thin fa-microphone-stand" style={{ font: "normal normal 300 28px/35px", margin: "10px 20px 40px 0px" }}></i>
                                {topic?.moderator.name}
                                { currentTopic?.moderator._id == currentParticipant._id && <div style={{ position:"absolute", right:"20px", bottom:"20px",  background:"transparent linear-gradient(91deg, #77EDDC 0%, #DBFD95 97%, #DEFE93 100%) 0% 0% no-repeat padding-box", color:"black", padding: "10px 20px", font: " normal normal 600 24px/33px Bio Sans", borderRadius:"8px" }} onClick={() => GoToNextTopic()}>NEXT TOPIC</div>}
                            </div>

                            
                        </div>
                    </>
                }
                {currentTopic?._id != topic._id &&
                    <>
                        <div style={{ fontSize: "74px", fontWeight: "bold", margin: "0px 20px", color: "#393357", display: "flex", justifyContent: "center", alignItems: "center" }}>{topicNumber}</div>
                        <div style={{ width: "100%", marginLeft:"20px" }}>
                            <div style={{ font: "normal normal 600 40px/44px Bio Sans'", color: "#393357", margin:"40px 20px 0px 0px" }}>{topic.name}</div>
                            <div style={{ font: "normal normal normal 32px/54px Bio Sans'", color: "#393357" }}>
                                
                                <i className="fa-thin fa-microphone-stand" style={{ font:"normal normal 300 28px/35px", margin:"10px 20px 40px 0px" }}></i>
                                {topic?.moderator.name}
                            </div>
                        </div>
                    </>
                }


            </div>

            {topic.moderator._id == currentParticipant._id &&
                <TopicDetailsComponent key={topic._id} topic={topic}></TopicDetailsComponent>
            }
        </div>
    );
}

export default TopicComponent;