import { useContext, useEffect, useRef, useState } from 'react';

import Lottie from 'react-lottie-player'

import { GlobalContext } from "../../contexts/globalContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons'

import styles from './ProjectElementComponent.module.css'
import TagComponent from './TagComponent';
import DetailCountComponent from './DetailCountComponent';
import background1 from '../../graphics/waves repeat 1.json';
import background2 from '../../graphics/waves repeat 2.json';
import background3 from '../../graphics/waves repeat 3.json';
import background4 from '../../graphics/waves repeat 4.json';

import DarkButtonComponent from '../commons/DarkButtonComponent';
import { GetMaxZIndex } from '../../utils/UtilitaryFunctions';


const ProjectElementComponent = ({ project, seeNotification, index, currentProjectList }) => {
    const elementRef = useRef()
    const viewButtonRef = useRef()

    const [firstRender, setFirstRender] = useState(true)

    const backgroundArray = [background1, background2, background3, background4]

    const { appHeight, openNotif, selectedTopicIdRef, setDetailsUpcomingConference, language, setSelectedProject, setUpcomingConferences, 
        projectsList, socketRef, setTopicFiles } = useContext(GlobalContext)

    const oyDivider = window.innerHeight / appHeight;

    useEffect(() => {
        if (project.conference !== null && project.isActive) {
            let upcomingConference = {
                name: project.conference.name,
                _id: project.conference._id,
                scheduleDate: project.conference.scheduleDate,
                project: project._id
            }
            setUpcomingConferences(upcomingConferences => [...upcomingConferences, upcomingConference])
        }
    }, [project])

    useEffect(() => {
        setFirstRender(true)
    }, [currentProjectList])

    useEffect(() => {
        if(seeNotification){
            setFirstRender(false)
        }
    }, [seeNotification])

    function formatDates(stringDate, status) {
        const date = new Date(stringDate)
        if (status === "created")
            return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`
        return `${date.toLocaleDateString('en-RO', { weekday: 'short' })}. ${date.getDate().toString().padStart(2, '0')} ${date.toLocaleDateString('en-RO', { month: 'short' })}, ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
    }

    function handleProjectSelect(id) {
        socketRef.current.emit("GET_PROJECT_BY_ID", {projectId: id, selectedTopicId: selectedTopicIdRef.current}, (project, conference, topicFiles) => {
            setSelectedProject(project)
            setDetailsUpcomingConference(conference)
            setTopicFiles(topicFiles)

            if (project.topics.length > 0 && !selectedTopicIdRef.current)
                selectedTopicIdRef.current = project.topics[0]._id;
            
        })
    }


    return (
        <div ref={elementRef} className={styles.project_element} >
            <Lottie
                loop={true}
                play={true}
                animationData={backgroundArray[index % 4]}
                style={{ position: "absolute", width: 1190, height: 1840, pointerEvents: 'none' }}
            />
            <div className={styles.creation_date_div}>{language.projects_list_element["Date Created"]}:
                <div className={styles.creation_date}>{formatDates(project.createdDate, "created")}</div>
            </div>
            <div className={styles.title_div}>
                <div className={styles.title_tags}>
                    {project.isActive ? <TagComponent status="active" text={language.projects_list_element.Active} /> :
                        <TagComponent status="inactive" text={language.projects_list_element.Inactive} />}
                    {project.isLive && <TagComponent status="live" text={language.projects_list_element["Live Conference"]} />}
                </div>
                <div className={styles.title} style={{ color: `${project.isActive ? "white" : "#CDCCD3"}` }}>{project.name}</div>
            </div>
            <div className={styles.details_div}>
                <div className={styles.details} style={{ height: 300 }}>
                    <div className={styles.half_detail}
                        style={{
                            borderRight: project.conference !== null ? "2px solid #FFFFFF33" : "0px",
                            color: project.isActive ? (project.isLive ? "#34FFC8" : "#FFFFFF") : "#CDCCD3",
                            width: project.conference !== null ? 455 : "100%",
                            paddingLeft: 0
                        }}>
                        {project.isActive ?
                            (project.isLive ?
                                language.projects_list_element["Live Conference"] :
                                (project.conference !== null ? language.projects_list_element["Upcoming Conference"] : language.projects_list_element["No upcoming conferences"])
                            )
                            :
                            (project.conference !== null ? language.projects_list_element["Last Conference"] : language.projects_list_element["No existing conference"])
                        }

                    </div>
                    {project.conference !== null && <div className={styles.half_detail} style={{paddingLeft: 40, width: 415, font: "normal normal normal 40px/42px 'Bio Sans'"}} >
                        <div style={{ color: project.isActive ? "#33FFC8" : "#CDCCD3", paddingBottom: 15 }}><b>{project.conference?.name}</b></div>
                        {formatDates(project.conference?.scheduleDate, "conference")}
                        {project.isLive && <div className={styles.join_conf}>{language.projects_list_element["Join Conference"]}</div>}
                    </div>}
                </div>
                <div className={styles.details} style={{ borderTop: "2px solid #FFFFFF33", borderBottom: "2px solid #FFFFFF33" }}>
                    <div className={styles.half_detail} style={{ borderRight: "2px solid #FFFFFF33", paddingLeft: 8, width: 447 }}>
                        <div className={styles.half_detail_title} >
                            <FontAwesomeIcon icon={byPrefixAndName.fal['users-rectangle']} />
                            {language.projects_list_element.Conferences}
                        </div>
                        <div className={styles.detail_accent} style={{paddingTop: 15}}>{project.nrConferences}</div>
                    </div>
                    <div className={styles.half_detail} style={{paddingLeft: 40, width: 415}}>
                        <div className={styles.half_detail_title} style={{ width: 350 }}>
                            <FontAwesomeIcon icon={byPrefixAndName.fal['calendar-clock']} />
                            {language.projects_list_element["Total Time Spent"]}
                        </div>
                        <div className={styles.detail_accent} style={{paddingTop: 15}}>{project.totalConferenceDuration.totalHours}:{project.totalConferenceDuration.totalMinutes}</div>
                    </div>
                </div>
                <div className={styles.details} style={{ justifyContent: "start", flexWrap: "wrap", width: 750, paddingTop: 40, paddingLeft: 8}}>
                    <DetailCountComponent project={project} type={"nrTopics"} />
                    <DetailCountComponent project={project} type={"nrSubtopics"} />
                    <DetailCountComponent project={project} type={"nrParticipants"} />
                    <DetailCountComponent project={project} type={"nrGuests"} />
                    <DetailCountComponent project={project} type={"nrFiles"} />
                </div>
            </div>
            {!firstRender && openNotif && <div className={seeNotification ? styles.bottomShadow : styles.bottomShadowClose} />}
            <div ref={viewButtonRef} className={firstRender ? "" : (seeNotification ? styles.buttonView : styles.buttonViewClose)} style={{zIndex: 100}}>
                
                <DarkButtonComponent onClick={() => handleProjectSelect(project._id)}
                    style={{
                        width: 587,
                        height: 57,
                        font: "normal normal 600 32px/32px Bio Sans",
                        color: project.isActive ? "" : "#CDCCD3",
                        border: project.isActive ? "" : "2px solid #CDCCD3",
                        position: firstRender ? "absolute" : "relative",
                        bottom: firstRender ? 50 : "",
                        left: firstRender ? "50%" : "",
                        transform: firstRender ? "translate(-50%,0px)" : "",
                        zIndex: 101
                    }}
                >
                    {project.isActive ? language.projects_list_element["View Project"] : language.projects_list_element["View Project"]}
                </DarkButtonComponent>
            </div>
        </div>



    );

}

export default ProjectElementComponent;