import styles from './LeftBodyComponent.module.css'



const LeftBodyComponent = ({image, title, description, needsCentering, isConference, type, subtitle, givenWidth}) => {
    
    return (
        <div className={styles.leftBody} style={{ width: givenWidth}} >
            <img src={image} className={styles.image} alt={"image"} style={{marginTop: needsCentering ? 415 : ""}}/>
            {type && <div className={styles.subtitle}>{subtitle}</div>}
            <div className={styles.imgTitle}>{title}</div>
            <div className={isConference ? styles.imgConfDescription : styles.imgDescription}>{description}</div>
        </div>
    );

}

export default LeftBodyComponent;