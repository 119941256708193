import { useRef, useEffect, useContext } from 'react';

import { GlobalContext } from "./contexts/globalContext";

import styles from './app.module.css';
import LoginComponent from './components/LoginComponent';
import ProjectsListComponent from './components/ProjectsListComponent';
import { jwtDecode } from 'jwt-decode';
import RegisterDeviceComponent from './components/registerDevice/RegisterDeviceComponent';
import { ApiGetRegisterStatus } from './utils/RestServices';
import SocketManager from './utils/SocketManager';

import ConferenceScreenComponent from "./components/conferenceScreen/ConferenceScreenComponent"

import AlertComponent from "./components/commons/AlertComponent"

function App() {

    const windowRef = useRef();   
    const { connectionToken, roomDataForRegister, setRoomDataForRegister, setDeskData, setDeviceId, currentUser, setGuestLogin, setUserLogin, appHeight, setAppHeight, appWidth, setAppWidth, currentConference } = useContext(GlobalContext);

    useEffect(() => {
        document.documentElement.style.setProperty('--overflowY',"hidden");

        //Calculate app heigt and width
        var ratioApp = process.env.REACT_APP_WIDTH / process.env.REACT_APP_HEIGHT;
        var ratioWindow = window.innerWidth / window.innerHeight;
        var newAppWidth = process.env.REACT_APP_WIDTH;
        var newAppHeight = process.env.REACT_APP_HEIGHT;

        if (ratioApp > ratioWindow) {
            var ratio = window.innerHeight * process.env.REACT_APP_WIDTH / (window.innerWidth * process.env.REACT_APP_HEIGHT);
            newAppHeight = process.env.REACT_APP_HEIGHT * ratio;
        } else {
            var ratio = window.innerWidth * process.env.REACT_APP_HEIGHT / (window.innerHeight * process.env.REACT_APP_WIDTH);
            newAppWidth = process.env.REACT_APP_WIDTH * ratio;
        }

        setAppHeight(newAppHeight);
        setAppWidth(newAppWidth);

        //End- Calculate app heigt and width

        windowRef.current.style.transformOrigin = 'top left';
        windowRef.current.style.transform = `scale(${window.innerWidth / newAppWidth}, ${window.innerHeight / newAppHeight})`;


        const urlParams = new URLSearchParams(window.location.search);

        const token = urlParams.get('token')
        const deviceIdParam = urlParams.get('deviceId')

        if (token) {
            let tokenData = getUserDataFromToken(token)
            if(tokenData.role === "GUEST"){
                setGuestLogin(tokenData)
            } else {
                setUserLogin(tokenData)
            }    
        }

        if(deviceIdParam){
            setDeviceId(deviceIdParam)

            console.log(deviceIdParam);

            var body = JSON.stringify({
                deviceId: deviceIdParam
            });

            ApiGetRegisterStatus(body, data => {
                if (data.registered) {
                    setDeskData(data.response);
                } else {
                    setRoomDataForRegister(data)
                }
            });
    
        }

    }, [])

    const getUserDataFromToken = (token) => {
        try {
            const decodedToken = jwtDecode(token);
            const { id, name, email, role } = decodedToken;
            return { id, name, email, role } 
        } catch (error) {
            console.error('Invalid token', error);
            return null;
        }
    };

    return (
        <div ref={windowRef} className={styles.app_body} style={{ height: `${appHeight}px`, width: `${appWidth}px` }}>
            {connectionToken && <SocketManager/>}

            {!currentUser && !roomDataForRegister && !currentConference && <LoginComponent />}
            {currentUser && <ProjectsListComponent />}
            {roomDataForRegister && <RegisterDeviceComponent/>}

            {currentConference && <ConferenceScreenComponent />}

            <AlertComponent/>
        </div>
    );
}

export default App;
