import { useContext, useEffect, useState } from 'react';
import styles from './PD_FileListComponent.module.css';

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons'

import PD_BaseListComponent from '../PD_BaseListComponent';
import PD_FileComponent from './PD_FileComponent';
import DarkButtonComponent from '../../commons/DarkButtonComponent';
import AlertComponent from '../../commons/AlertComponent';


const FileListComponent = () => {
    const { socketRef, language, selectedProject, topicFiles, setTopicFiles, selectedTopicIdRef, setAlertMessage } = useContext(GlobalContext);
    const { setAddFiles, addTopic, editTopic } = useContext(ProjectDetailsContext)


    useEffect(() => {
        socketRef.current.emit("PROJECT_GET_FOLDER_ATTACHED_DOCUMENTS", { parentId: selectedTopicIdRef.current }, (docs) => {
            setTopicFiles(docs);
        });
    },[selectedTopicIdRef.current])


    return (
        <PD_BaseListComponent
            title = {language.project_details.Files}
            list = {topicFiles.length > 0 && topicFiles.map((file) => (
                <PD_FileComponent key={file._id} file={file}/>
            ))}
            button = {
                <DarkButtonComponent onClick={() => selectedProject.topics.length > 0 ? setAddFiles(true) : setAlertMessage({ type: "WARNING", message: "Files can only be added to folders. Select or create a folder to proceed." })} style={{width: 1125, marginLeft: -57, marginBottom: 57, backdropFilter: "blur(31px)", opacity: selectedProject.topics.length > 0 ? 1 : 0.5}}>
                    {/* <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['files']} /> */}
                    {language.project_details['Add files']}
                </DarkButtonComponent>
            }
        />

    );

}

export default FileListComponent;