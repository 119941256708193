

import styles from './RightBodyComponent.module.css'



const RightBodyComponent = ({children, flex, width = 1600, paddingLeft = 80}) => {
    
    return (
        <div className={styles.component} style={{flexDirection: flex ? "row" : "", width, paddingLeft }}>
            {children}
        </div>
    );

}

export default RightBodyComponent;