import { useRef, useContext, useState } from 'react';
import styles from './AddMembersComponent.module.css'

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import InputTextComponent from '../../keyboard/InputTextComponent';
import KeyboardComponent from '../../keyboard/KeyboardComponent';
import PopupHeaderComponent from '../common/PopupHeaderComponent';
import PopupBodyComponent from '../common/PopupBodyComponent';
import PopupBaseComponent from '../common/PopupBaseComponent';
import ClosePageButtonComponent from '../../commons/ClosePageButtonComponent'
import RightBodyComponent from '../common/RightBodyComponent';
import LeftBodyComponent from '../common/LeftBodyComponent';

import membersAdd from '../../../graphics/Popups/Icon Members Add.png'
import FooterButtonsComponent from '../common/FooterButtonsComponent';
import OutsideGlowComponent from '../common/OutsideGlowComponent';
import CustomDropdownComponent from '../../commons/CustomDropdownComponent';


const AddMembersComponent = () => {

    const inputRefName = useRef()
    const inputRefEmail = useRef()
    const inputRefPass = useRef()
    const inputRefPassConf = useRef()
    const keyboardRef = useRef()

    const [isOnFocusName, setIsOnFocusName] = useState(false)
    const [isOnFocusEmail, setIsOnFocusEmail] = useState(false)
    const [isOnFocusPass, setIsOnFocusPass] = useState(false)
    const [isOnFocusPassConf, setIsOnFocusPassConf] = useState(false)
    const [passwordMistake, setPasswordMistake] = useState(false)


    const { language, selectedProject, socketRef, departmentList, functionList, setAlertMessage, appWidth, appHeight } = useContext(GlobalContext);
    const { setAddMembers } = useContext(ProjectDetailsContext);

    const [currentChoiceDepartment, setCurrentChoiceDepartment] = useState({
        value: "AMN",
        text: "Admin"
    })

    const [currentChoiceFunction, setCurrentChoiceFunction] = useState({
        value: "PROG",
        text: "Programmer"
    })

    const optionsDepartament = [
        {
            value: "AMN",
            text: "Adminstrator"
        },
        {
            value: "DEVL",
            text: "Development"
        },
        {
            value: "NTF",
            text: "NOTIF"
        },
    ]

    const optionsFunction = [
        {
            value: "PROG",
            text: "Programmer"
        },
        {
            value: "ADMIN",
            text: "Admin"
        },
        {
            value: "DEV",
            text: "Developer"
        },
        {
            value: "GST",
            text: "GUEST"
        },
    ]

    const allFieldsCompleted = () => {
        const name = inputRefName.current?.value?.trim() || ""; 
        const email = inputRefEmail.current?.value?.trim() || "";
        const password = inputRefPass.current?.value?.trim() || "";
        const confirmPassword = inputRefPassConf.current?.value?.trim() || "";

        return (
            name.length > 0 &&
            email.length > 0 &&
            password.length > 0 &&
            confirmPassword.length > 0
        );
    };

    const handleInputChange = () => {
        setPasswordMistake(false); 
    }

    function handleEmailAdd() {
        let memberFunction = functionList.find(func => func.code === currentChoiceFunction.value)
        let memberDepartment = departmentList.find(dep => dep.code === currentChoiceDepartment.value)

        if (inputRefPass.current.value === inputRefPassConf.current.value) {
            socketRef.current.emit("CREATE_USER_AND_ADD_TO_PROJECT", {
                projectId: selectedProject._id,
                userData: {
                    "name": inputRefName.current.value,
                    "email": inputRefEmail.current.value,
                    "password": inputRefPass.current.value,
                    "function_id": memberFunction._id,
                    "department_id": memberDepartment._id,
                    "role": "MEMBER",
                }
            }, (callback) => {
                if (callback) console.log(callback)
                setAddMembers(false)
            })
        } else {
            setAlertMessage({ type: "ERROR", message: "Passwords do not match. Please ensure password and Confirm Password fields are identical." });

        }

    }


    return (
        <PopupBaseComponent>
            <KeyboardComponent visible ref={keyboardRef} x={(appWidth - 2140 - 60) / 2} y={appHeight * 0.63} width="2140px" height="610px" s="1" />
            <PopupHeaderComponent text={language.add_guests['Add Guest']} />
            <PopupBodyComponent style={{ width: 2700, height: 840, top: "12%" }}>
                <ClosePageButtonComponent setClose={() => setAddMembers(false)} />
                <LeftBodyComponent givenWidth="800px" image={membersAdd} title="Add member to project" description="Create and add a new member. You will automatically add this as a member in the organization, as well as a member to the project." />
                <RightBodyComponent>
                    <div className={styles.title}>New Member</div>

                    <div className={styles.bodyDetails}>
                        <div className={styles.column}>
                            <div className={styles.subTitle}>Name</div>
                            {isOnFocusName && <OutsideGlowComponent style={{ top: 97, left: 11, width: 801 }} />}
                            <InputTextComponent ref={inputRefName} keyboardRef={keyboardRef} value=""
                                placeHolder="Enter name here" styleType='POPUP' width="760px" fontSize="40px" onChange={handleInputChange} setIsOnFocus={setIsOnFocusName} />
                            {!isOnFocusName && <FontAwesomeIcon className={styles.iconEdit} icon={byPrefixAndName.fal['pen']} />}

                            <div className={styles.subTitle}>Password</div>
                            {isOnFocusPass && <OutsideGlowComponent style={{ top: 289, left: 11, width: 801 }} />}
                            <InputTextComponent type="password" ref={inputRefPass} keyboardRef={keyboardRef} value="" placeHolder="Enter password here"
                                onChange={handleInputChange} styleType='POPUP' width="760px" fontSize="40px" setIsOnFocus={setIsOnFocusPass} />
                            {!isOnFocusPass && <FontAwesomeIcon className={styles.iconEdit} style={{ top: 193 }} icon={byPrefixAndName.fal['pen']} />}

                            <div className={styles.subTitle}>Department</div>
                            <CustomDropdownComponent marginLeftText = {10} style={{marginLeft: 0, marginTop: 16}}  options={optionsDepartament} currentChoice={currentChoiceDepartment} setCurrentChoice={setCurrentChoiceDepartment} width={806} />
                        </div>

                        <div className={styles.column} style={{paddingLeft: 40, position: "fixed", left: "calc(66%)"}}>
                            <div className={styles.subTitle}>Email</div>
                            {isOnFocusEmail && <OutsideGlowComponent style={{ top: 97, left: 31, width: 801}} />}
                            <InputTextComponent ref={inputRefEmail} keyboardRef={keyboardRef} value="" placeHolder="Enter email here"
                                onChange={handleInputChange} styleType='POPUP' width="760px" fontSize="40px" setIsOnFocus={setIsOnFocusEmail} />
                            {!isOnFocusEmail && <FontAwesomeIcon className={styles.iconEdit} style={{ left: 775 }} icon={byPrefixAndName.fal['pen']} />}

                            <div className={styles.subTitle}>Confirm Password</div>
                            {isOnFocusPassConf && <OutsideGlowComponent style={{top: 289, left: 31, width: 801 }} />}
                            <InputTextComponent type="password" ref={inputRefPassConf} keyboardRef={keyboardRef} value="" placeHolder="Re-enter password here"
                                onChange={handleInputChange} styleType='POPUP' width="760px" fontSize="40px" setIsOnFocus={setIsOnFocusPassConf} />
                            {!isOnFocusPassConf && <FontAwesomeIcon className={styles.iconEdit} style={{ top: 193, left: 775 }} icon={byPrefixAndName.fal['pen']} />}

                            <div className={styles.subTitle}>Function</div>
                            <CustomDropdownComponent marginLeftText = {10} style={{marginLeft: 0, marginTop: 16}} options={optionsFunction} currentChoice={currentChoiceFunction} setCurrentChoice={setCurrentChoiceFunction} width={806} />
                        </div>
                    </div>

                    <div style={{marginLeft: 20}}><FooterButtonsComponent lightText={"Create"} isOpaque={!allFieldsCompleted()} lightClick={handleEmailAdd} darkClick={setAddMembers} /></div>
                    
                </RightBodyComponent>
            </PopupBodyComponent>

        </PopupBaseComponent>
    );

}

export default AddMembersComponent;