import { useContext, useEffect, useRef, useState } from 'react';
import styles from './PD_FileComponent.module.css';
import { ProjectDetailsContext } from "../../../contexts/projectDetailsContext";
import { GlobalContext } from "../../../contexts/globalContext";

import MoreButtonComponent from '../../commons/MoreButtonComponent';
import DeleteNotificationComponent from '../../popup/common/DeleteNotificationComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

const PD_FileComponent = ({ file }) => {
    const moreButtonRef = useRef();

    const { selectedRefTypeForFiles, selectedTopicIdRef, socketRef, selectedProject } = useContext(GlobalContext);
    const { moreClicked, setMoreClicked } = useContext(ProjectDetailsContext);

    const [isDelete, setIsDelete] = useState(false); // State to manage delete confirmation dialog

    // Handles deletion of the file after user confirmation
    const handleDeleteFile = () => {
        socketRef.current.emit(
            "PROJECT_DELETE_DOCUMENT",
            { 
                parentId: selectedTopicIdRef.current, 
                refType: selectedRefTypeForFiles || "Topic", 
                projectFileId: file._id, 
                projectId: selectedProject._id 
            },
            () => {
                setMoreClicked({}); // Reset the moreClicked state
                setIsDelete(false); // Close the delete dialog
            }
        );
    };

    const icon = (() => {
        if (file.docsFile.type.includes("image")) {
            return "file-image";
        } else if (file.docsFile.type.includes("video")) {
            return "file-video";
        } else if (file.docsFile.type.includes("pdf")) {
            return "file-pdf";
        } else if (file.docsFile.type.includes("word")) {
            return "file-word";
        } else if (file.docsFile.type.includes("excel")) {
            return "file-excel";
        } else {
            return "file-lines";
        }
    })();

    function handleClick() {
        if (moreClicked.shown && file._id === moreClicked.id) {
            setMoreClicked({});
        } else {
            setMoreClicked({
                ...moreClicked,
                shown: true,
                id: file._id,
                clickRemove: () => setIsDelete(true), // Opens the delete confirmation dialog
                x: moreButtonRef.current.getBoundingClientRect().x,
                y: moreButtonRef.current.getBoundingClientRect().y
            });
        }
    }

    const createImage = () => {
        return process.env.REACT_APP_CMS_URL + file.docsFile.path
    };


    return (
        <div key={file._id} className={styles.element}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <div className={styles.info}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas[icon]} />

                    <div className={styles.participantDetails}>
                        <div style={{maxWidth: 1000}}>{file.docsFile.name}</div>
                        <div className={styles.participantDescription}>
                            Added by:
                            <div className={styles.participantRole}>{file.addedBy?.name}</div>
                        </div>
                        {/* <div className={styles.participantDescription}>
                            Owner:
                            <div className={styles.participantRole}>{file.docsFile.users[0]}</div>
                        </div> */}
                    </div>
                </div>
                
                
                <MoreButtonComponent ref={moreButtonRef} clicked={moreClicked} setClicked={handleClick} id={file._id} />
                {/* Delete Notification Component */}
                {isDelete && (
                    <DeleteNotificationComponent 
                        name={"File"} 
                        type={'participant'}
                        funct={handleDeleteFile} // Deletes the file when confirmed
                        onClose={() => setIsDelete(false)} // Closes the delete dialog without action
                    />
                )}
            </div>
            {file.docsFile.type.includes("image") && <img className={styles.image} alt='image' src={createImage()}/>}
        </div>
    );
};

export default PD_FileComponent;
