import { useContext, useState, useEffect, useRef} from 'react';

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';

// import styles from './AddParticipantsComponent.module.css'
import PopupBodyComponent from '../common/PopupBodyComponent';
import PopupBaseComponent from '../common/PopupBaseComponent';
import LeftBodyComponent from '../common/LeftBodyComponent';

import membersAdd from '../../../graphics/Popups/Icon Members Add.png'
import ParticipantsListComponent from './ParticipantsListComponent';
import UsersListComponent from './UsersListComponent';
import KeyboardComponent from '../../keyboard/KeyboardComponent';


const AddPartipicantsComponent = () => {
    const keyboardRef = useRef()

    const [attachedParticipants, setAttachedParticipants] = useState([])
    const [isOnFocus, setIsOnFocus] = useState(false)

    const { appWidth, appHeight } = useContext(GlobalContext);
    const { } = useContext(ProjectDetailsContext);


    return (
        <PopupBaseComponent>
            {isOnFocus && <KeyboardComponent visible ref={keyboardRef} x={(appWidth - 2140 - 60) / 2} y={appHeight * 0.7} width="2140px" height="610px" s="1" />}
            <PopupBodyComponent style={{width: 1830, height: 1770, left: "40%"}}>
                <LeftBodyComponent givenWidth="800px" image={membersAdd} title="Add members to project" description="Select one or more members from the list below to add them to the project. The selected members will gain access to project resources and receive all relevant updates and notifications."/>
                <ParticipantsListComponent attachedParticipants={attachedParticipants} setAttachedParticipants={setAttachedParticipants} isOnFocus={isOnFocus} setIsOnFocus={setIsOnFocus}/>
            </PopupBodyComponent>
            <UsersListComponent attachedParticipants={attachedParticipants} setAttachedParticipants={setAttachedParticipants} />
        </PopupBaseComponent>
    );

}

export default AddPartipicantsComponent;