import {  useContext, useEffect, useState } from 'react';

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';

import styles from './UsersListComponent.module.css'
import PopupBodyComponent from '../common/PopupBodyComponent';
import FooterButtonsComponent from '../common/FooterButtonsComponent';
import ClosePageButtonComponent from '../../commons/ClosePageButtonComponent';
import InitialsCircleComponent from '../../commons/InitialsCircleComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons'

import { GetMaxZIndex } from '../../../utils/UtilitaryFunctions';

const UsersListComponent = ({attachedParticipants, setAttachedParticipants}) => {

    
    const [isCheck, setIsCheck] = useState([]);


    const { selectedProject, socketRef } = useContext(GlobalContext);
    const { setAddParticipants } = useContext(ProjectDetailsContext);

    // let attachedParticipants = []

    const [spaces, setSpaces] = useState([ 0, 1, 2, 3, 4, 5, 6, 7])

    function handleParticipantsAdd(){
        let participantIds= []

        for(let i = 0; i < attachedParticipants.length; i++){
            participantIds.push(attachedParticipants[i]._id)
        }

        socketRef.current.emit("ADD_PARTICIPANTS_TO_PROJECT",
            { projectId: selectedProject._id, participantIds }, () => { }
        );

        setAddParticipants(false)
    }

    const handleRemoveParticipant = (participant) => {
        setAttachedParticipants((prevParticipants) =>
            prevParticipants.filter((prevParticipant) => prevParticipant._id !== participant._id)
        );
    }

    useEffect(() => {
        if(attachedParticipants.length === spaces.length){
            setSpaces((arr) => [...arr, attachedParticipants.length])
        }
    }, [attachedParticipants])


    return (
        <PopupBodyComponent style={{width: 860, height: 1770, left: "69.5%", flexDirection: "column"}} >
            <ClosePageButtonComponent setClose={() => setAddParticipants(false)} />
            <div className={styles.title}>Participants</div>
            <div className={styles.addedParticipantsList}>
                {spaces.map((space) => {
                    let assignedParticipant = null

                    if(space < attachedParticipants.length){
                        assignedParticipant = attachedParticipants[space]
                    }

                    return (
                        <div className={styles.positionCard} key={space} style={{opacity: assignedParticipant ? 1 : 0.4}}>
                            {assignedParticipant && <div className={styles.assignedParticipant}>
                                <InitialsCircleComponent name={assignedParticipant.name}
                                    style={{ marginLeft: 25, height: 121, width: 121, border: "6px solid #0D0032", marginBottom: 10, marginTop: 20, font: 'normal normal normal 54px / 98px "Bio Sans"' }} />
                                <div className={styles.participantDetails}>

                                    {assignedParticipant.role}
                                    <div className={styles.participantNameShadow} style={{zIndex: GetMaxZIndex() + 1}}/>
                                    <div className={styles.participantName}>
                                        {assignedParticipant.name} 
                                    </div>
                                    
                                    {assignedParticipant.function?.name} {assignedParticipant.function ? "|" : ""} {assignedParticipant.department?.name}
                                </div>
                                <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fal['close']} onClick={() => handleRemoveParticipant(assignedParticipant)}/>
                            </div>}
                        </div>
                    );
                })}
            </div>
            <div className={styles.participantListShadowUp} style={{ zIndex: GetMaxZIndex() + 1, pointerEvents: 'none' }} />
            <div className={styles.participantListShadow} style={{ zIndex: GetMaxZIndex() + 1, pointerEvents: 'none' }} />
            <FooterButtonsComponent style={{marginLeft: 65}} lightText={"Add"} isOpaque={attachedParticipants.length === 0} 
            lightClick={handleParticipantsAdd} darkClick={setAddParticipants} lightWidth={329} darkWidth={329}/>
        </PopupBodyComponent>

    );

}

export default UsersListComponent;