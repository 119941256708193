import { useRef, useContext, useState, useEffect } from 'react';
import styles from './ConferenceAddNewSubtopicComponent.module.css'

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import InputTextComponent from '../../keyboard/InputTextComponent';
import KeyboardComponent from '../../keyboard/KeyboardComponent';
import PopupBodyComponent from '../common/PopupBodyComponent';
import PopupBaseComponent from '../common/PopupBaseComponent';
import ClosePageButtonComponent from '../../commons/ClosePageButtonComponent'
import RightBodyComponent from '../common/RightBodyComponent';
import LeftBodyComponent from '../common/LeftBodyComponent';

import membersAdd from '../../../graphics/Popups/IconMembersAdd.png'
import FooterButtonsComponent from '../common/FooterButtonsComponent';
import OutsideGlowComponent from '../common/OutsideGlowComponent';
import { AddConferenceContext } from '../../../contexts/addConferenceContext';



const ConferenceAddNewSubtopicComponent = () => {

    const keyboardRef = useRef()
    const nameRef = useRef()

    const [isOnFocus, setIsOnFocus] = useState(false)

    const { socketRef, language, appWidth } = useContext(GlobalContext);
    const { editConference, setEditConference } = useContext(ProjectDetailsContext)
    const { setAddNewSubtopic, selectedTopic, selectedSubtopic,  } = useContext(AddConferenceContext);



    function addSubtopic() {
        socketRef.current.emit('SUBTOPIC_UPDATE_TOPIC', {
            topicId: selectedTopic._id,
            subtopicId: selectedSubtopic ? selectedSubtopic._id : null,
            newData: {
                name: nameRef.current?.value ? nameRef.current?.value : "Default Title",
            }
        },
            callback => {
                // console.log(callback)
                if (!callback.error) {
                    let changedTopic = {}
                    if (selectedSubtopic) {
                        changedTopic = {
                            ...selectedTopic, 
                            subtopics: selectedTopic.subtopics.map((subtopic) =>
                                subtopic._id === selectedSubtopic._id ? callback.response : subtopic
                            )
                        };

                    } else {
                        changedTopic = {
                            ...selectedTopic, 
                            subtopics: [...(selectedTopic.subtopics || []), callback.response], 
                        };
                        
                    }
                    setEditConference((prev) => ({
                        ...prev,
                        topics: prev.topics.map((topic) =>
                            topic._id === selectedTopic._id ? changedTopic : topic
                        ),
                    }));
                    setAddNewSubtopic(false)
                }

            })
    }


    return (
        <PopupBaseComponent>
            <KeyboardComponent visible ref={keyboardRef} x={(appWidth - 2140 - 60) / 2} y="1580" width="2140px" height="610px" s="1" />

            <PopupBodyComponent style={{ width: 2760, height: 800, top: "15%" }}>
                <ClosePageButtonComponent setClose={() => setAddNewSubtopic(false)} />
                <LeftBodyComponent image={membersAdd} title="New Subtopic" description="Organize subtopic details and upload relevant files here to keep everything structured and accessible." />
                <RightBodyComponent>
                    <div className={styles.title}>Subtopic Title</div>
                    {isOnFocus && <OutsideGlowComponent style={{ left: 1231.5, top: 389 }} />}
                    <InputTextComponent ref={nameRef} keyboardRef={keyboardRef} value={selectedSubtopic ? selectedSubtopic.name : ""} placeHolder="Enter subtopic title here" styleType='POPUP' width="1560px" fontSize="40px" setIsOnFocus={setIsOnFocus} />
                    {!isOnFocus && <FontAwesomeIcon className={styles.iconEdit} icon={byPrefixAndName.fal['pen']} />}
                    <FooterButtonsComponent lightText={selectedSubtopic ? "Update" : "Create"} lightClick={addSubtopic} darkClick={setAddNewSubtopic} style={{ bottom: 336 }} />
                </RightBodyComponent>
            </PopupBodyComponent>

        </PopupBaseComponent>
    );

}

export default ConferenceAddNewSubtopicComponent;