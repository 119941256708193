import { useRef, useState, useEffect, forwardRef } from 'react';
import Keyboard from 'react-simple-keyboard';

import 'react-simple-keyboard/build/css/index.css';
import './KeyboardComponent.css';

import { GetMaxZIndex } from '../../utils/UtilitaryFunctions';

const KeyboardComponent = forwardRef((props, ref) => {
    const currentInputForKeyboard = useRef();
    const compRef = useRef();
    const buttonClickedRef = useRef()

    const [layoutName, setLayoutName] = useState("default");
    const [inputName, setInputName] = useState("default");
    const [shiftOn, setShiftOn] = useState(false);


    const [clickedButton, setClickedButton] = useState(null);


    useEffect(() => {
        ref.current.setInputElement = (element) => {
            currentInputForKeyboard.current = element;

            setInputName(currentInputForKeyboard.current.getAttribute("name"));


            if (props.onVisible) {
                props.onVisible();
            }

            if (currentInputForKeyboard.current.keyboardOpenAt) {
                let splt = currentInputForKeyboard.current.keyboardOpenAt.split(",");
                let xx = Number(splt[0]);
                let yy = Number(splt[1]);
                compRef.current.style.transform = `matrix(1, 0, 0, 1, ${xx},  ${yy})`;
            }
        }

        ref.current.wasButtonClicked = () => {
            let oldValue = buttonClickedRef.current;

            buttonClickedRef.current = false

            return oldValue
        }

        ref.current.hideIt = () => {
            if (props.onClose) {
                props.onClose();
            }
        }

        if (props.visible) {
        }
    }, [ref.current])

    useEffect(() => {
        if (inputName && inputName !== "default") {
            ref.current.setInput(currentInputForKeyboard.current.value);
        }
    }, [inputName])


    const onKeyPress = button => {
        if (button === "{lock}") handleLock();
        else if (button === "{shift}") handleShift()
        else if(shiftOn === true) handleShift()
        setClickedButton(button)
        buttonClickedRef.current = true

        console.log("press")
    };

    const handleLock = () => {
        setLayoutName((layoutName === "default") ? "shift" : "default");
    };

    const handleShift = () => {
        setLayoutName((layoutName === "default") ? "shift" : "default");
        setShiftOn(!shiftOn)
    };

    const onChangeAll = inputs => {
        if(currentInputForKeyboard.current){
            currentInputForKeyboard.current.value = inputs[currentInputForKeyboard.current.getAttribute("name")];
            currentInputForKeyboard.current.onTextChanged();
        } 
        
    };



    return (

        <div ref={compRef} style={{ zIndex: GetMaxZIndex() + 1, fontSize:"32px", position: "absolute", 
                                    transform: `matrix(${props.s}, 0, 0, ${props.s}, ${props.x}, ${props.y})`, 
                                    width: props.width, height: props.height}}>
            <Keyboard
                keyboardRef={r => (ref.current = r)}
                layoutName={layoutName}
                inputName={inputName}
                onKeyPress={onKeyPress}
                onKeyReleased={() => {
                    setClickedButton(null)
                    if (currentInputForKeyboard.current) {
                        currentInputForKeyboard.current.focus();
                    }
                }}
                onChangeAll={onChangeAll}
                theme="hg-theme-default hg-layout-default myTheme"
                layout={{
                    default: [
                      "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                      "{tab} q w e r t y u i o p [ ] \\",
                      "{lock} a s d f g h j k l ; ' {enter}",
                      "{shift} z x c v b n m , . / {shift}",
                      ".com {space}"
                    ],
                    shift: [
                      "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                      "{tab} Q W E R T Y U I O P { } |",
                      "{lock} A S D F G H J K L : \" {enter}",
                      "{shift} Z X C V B N M < > ? {shift}",
                      ".com {space}"
                    ]
                }}
                buttonTheme={[
                    {
                        class: "hg-conf",
                        buttons: "{space} {bksp} .com {tab} {lock} {shift} {enter} q w e r t y u i o p [ ] \\ a s d f g h j k l ; '  z x c v b n m , . / A B C D E F G H I J K L M N O P Q R S T U W V X Y Z < > ? { } | :"
                    },
                    {
                        class: "hg-conf",
                        buttons: '"'
                    },
                    {
                        class: "hg-conf-first-line",
                        buttons: "` 1 2 3 4 5 6 7 8 9 0 - = ! @ # $ % ^ & * ( ) _ + ~"
                    },
                    {
                        class: "hg-conf-com",
                        buttons: '.com'
                    },
                    {
                        class: "hg-conf-tab",
                        buttons: '{tab}'
                    },
                    {
                        class: "hg-conf-caps",
                        buttons: '{lock}'
                    },
                    {
                        class: "hg-conf-shift",
                        buttons: '{shift}'
                    },
                    {
                        class: "hg-conf-enter",
                        buttons: '{enter}'
                    },
                    {
                        class: "hg-conf-bksp",
                        buttons: '{bksp}'
                    },
                    {
                        class: clickedButton ? "hg-conf-click" : "hg-conf-bksp",
                        buttons: clickedButton ? clickedButton : '{bksp}'
                    }
                ]}
            />
        </div>

    );
})


export default KeyboardComponent;