import { useContext, useEffect, useState } from 'react';

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';

import PD_BaseListComponent from '../PD_BaseListComponent';
import PD_ParticipantComponent from './PD_ParticipantComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons'

import DarkButtonComponent from '../../commons/DarkButtonComponent';
import styles from './PD_ParticipantListComponent.module.css'



const ParticipantListComponent = () => {
    
    const { language, selectedProject, currentUser } = useContext(GlobalContext);
    const { setAddParticipants} = useContext(ProjectDetailsContext);


    const [sortedParticipants, setSortedParticipants] = useState(selectedProject.participants.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    }))

    useEffect(() => {
        const sort = selectedProject.participants.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        });
        setSortedParticipants(sort)
    },[selectedProject.participants])

    return (
        <PD_BaseListComponent 
            title={language.project_details.Participants}
            list = {sortedParticipants.length > 1 && sortedParticipants?.map((participant) => (
                <PD_ParticipantComponent key={participant._id} participant={participant} />
            ))}
            button = {
                (currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator") && <DarkButtonComponent onClick={() => setAddParticipants(true)} style={{width: 1125, marginLeft: -57, marginBottom: 57, backdropFilter: "blur(31px)"}}>
                    {/* <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['users']} /> */}
                    Add participants
                </DarkButtonComponent>
            }
        />

    );

}

export default ParticipantListComponent;