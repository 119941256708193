import { useRef, useContext, useState, useEffect } from 'react';
import styles from './ConferenceAddNewTopicComponent.module.css'

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';
import { AddConferenceContext } from '../../../contexts/addConferenceContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import InputTextComponent from '../../keyboard/InputTextComponent';
import KeyboardComponent from '../../keyboard/KeyboardComponent';
import PopupBodyComponent from '../common/PopupBodyComponent';
import PopupBaseComponent from '../common/PopupBaseComponent';
import ClosePageButtonComponent from '../../commons/ClosePageButtonComponent'
import RightBodyComponent from '../common/RightBodyComponent';
import LeftBodyComponent from '../common/LeftBodyComponent';

import iconAdd from '../../../graphics/Popups/Icon Conferinta Add.png'
import iconEdit from '../../../graphics/Popups/Icon Edit Topic.png'
import FooterButtonsComponent from '../common/FooterButtonsComponent';
import OutsideGlowComponent from '../common/OutsideGlowComponent';
import LightButtonComponent from '../../commons/LightButtonComponent';
import AddModeratorPopup from './AddModeratorPopup';


const ConferenceAddNewTopicComponent = () => {
    const keyboardRef = useRef()
    const nameRef = useRef()

    const [isOnFocus, setIsOnFocus] = useState(false)

    const { socketRef, currentUser, appWidth } = useContext(GlobalContext);
    const { editConference, setEditConference } = useContext(ProjectDetailsContext)
    const { setAddNewTopic, selectedTopic, addModerator, setAddModerator, selectedModerator, setSelectedModerator, setUniqueModerators } = useContext(AddConferenceContext);


    useEffect(() => {
        if(selectedTopic){
            setSelectedModerator(selectedTopic.moderator)
        }
        return () => {
            setSelectedModerator()
        }
    }, [])

    function addTopic() {
        socketRef.current.emit('CONFERENCE_UPDATE_TOPIC', {
            conferenceId: editConference._id, 
            topicId: selectedTopic ? selectedTopic._id : null, 
            newData: {
                name: nameRef.current?.value ? nameRef.current?.value : "Default Title", 
                moderator: selectedModerator._id
            }
        }, callback => {
            console.log(callback)
            if(!callback.error){
                if(!callback.error){
                    if(selectedTopic){
                        setEditConference((prev) => ({
                            ...prev,
                            moderators: callback.response.moderators,
                            topics: prev.topics.map((topic) =>
                                topic._id === selectedTopic._id ? callback.response.updatedTopic : topic
                            ),
                        }))
                        
                    } else {
                        setEditConference((prev) => ({
                            ...prev, 
                            moderators: callback.response.moderators,
                            topics: [...prev.topics, callback.response.updatedTopic], 
                        }));
                    }
                    setAddNewTopic(false)
                }
            }
        })
    }
    
    return (
        <PopupBaseComponent>
            {/* <KeyboardComponent visible ref={keyboardRef} x="1519" y="1580" width="2082px" height="800px" s="1" /> */}
            <KeyboardComponent visible ref={keyboardRef} x={(appWidth - 2140 - 60) / 2} y="1580" width="2140px" height="610px" s="1" />

            <PopupBodyComponent style={{width: 2760, height: 942, top: "12%"}}>
                <ClosePageButtonComponent setClose={() => setAddNewTopic(false)} />
                <LeftBodyComponent image={selectedTopic ? iconEdit : iconAdd} title="New Topic" description="Provide a topic name and assign a moderator. Adding a moderator is mandatory for creating a topic in a conference."/>
                <RightBodyComponent>
                    <div className={styles.title}>Topic Title *</div>
                    {isOnFocus && <OutsideGlowComponent style={{left: 1231, top: 239, width: 1601}}/>}
                    <InputTextComponent ref={nameRef} keyboardRef={keyboardRef} value={selectedTopic ? selectedTopic.name : ""} placeHolder="Enter topic title here" 
                    styleType='POPUP' width= "1560px" fontSize="40px" setIsOnFocus={setIsOnFocus}/>
                    {!isOnFocus && <FontAwesomeIcon className={styles.iconEdit} icon={byPrefixAndName.fal['pen']} />}
                    <div className={styles.title}>Moderator *</div>
                    {!selectedModerator ? <LightButtonComponent style={{marginLeft: 0, width: 430}} onClick={() => {
                        if(currentUser?.role.toLowerCase() === "administrator")
                            setAddModerator(true)
                    }}>
                        <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['plus']} /> Add moderator
                    </LightButtonComponent> :
                    <div className={styles.moderatorName} onClick={() => {
                        if(currentUser?.role.toLowerCase() === "administrator")
                            setAddModerator(true)
                    }}>
                        {selectedModerator.name}
                    </div>}
                    <div className={styles.explanation}>⚠️ <span style={{opacity: 0.5}}>Fields marked with * are mandatory</span></div>
                    <FooterButtonsComponent lightText={selectedTopic ? "Update" : "Create"} isOpaque={(!selectedTopic && !nameRef.current?.value && !selectedModerator&& !isOnFocus)} lightClick={addTopic} darkClick={setAddNewTopic} style={{ bottom: 136}}/>
                </RightBodyComponent>
            </PopupBodyComponent>
            {addModerator && <AddModeratorPopup/>}
            
        </PopupBaseComponent>
    );

}

export default ConferenceAddNewTopicComponent;