import { useEffect, useRef, useState, useContext } from 'react';

import { GlobalContext } from "../../contexts/globalContext";

const SideMenuComponent = ({ SetWaitingForJoining }) => {

    const { currentConference, conferenceSocketRef, currentParticipant } = useContext(GlobalContext)

    const [hour, setHour] = useState("00");
    const [minutes, setMinutes] = useState("00");
    const [dateString, setDateString] = useState("00");

    const intervalRef = useRef();

    useEffect(() => {
        const date = new Date();
        setHour(date.getHours());
        setMinutes(date.getMinutes());
        setDateString(date.toDateString());


        intervalRef.current = setInterval(() => {
            const date = new Date();
            setHour(date.getHours());
            setMinutes(date.getMinutes());
            setDateString(date.toDateString());
        }, 1000);

        return () => {
            clearInterval(intervalRef.current);
        }

    }, []);

    const StopConference = () => {
        conferenceSocketRef.current.emit("STOP_CONFERENCE", currentConference._id);
    }

    return (

        <div style={{ position: "absolute", display: "flex", flexDirection: "column", width: "125px", height: "auto", bottom: "20px", left: "20px", top: "20px", backgroundColor: "#292148", borderRadius: "10px", justifyContent: "center", alignItems: "center" }}>
            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
                <i className="fa-thin fa-clock" style={{ fontSize: "70px", color: "#726FA8", marginBottom: "0px" }}></i>
                <div style={{ font: "normal normal 600 71px/64px 'Bio Sans'", color: "white" }}> {hour}</div>
                <div style={{ font: "normal normal 300 71px/64px 'Bio Sans'", color: "white" }}> {minutes}</div>
                <div style={{ font: "normal normal 600 28px/32px Bio Sans", color: "#726FA8", margin: "10px 10px" }}> {dateString}</div>
            </div>

            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>

                <div style={{ cursor:"pointer", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "40px" }}>
                    <i className="fa-thin fa-messages" style={{ fontSize: "70px", color: "#726FA8" }}></i>
                    <div style={{ font: "normal normal 600 18px/22px Bio Sans", color: "#726FA8", marginTop: "10px", margin: "0px 10px" }}>CHAT</div>
                </div>

                <div style={{ cursor:"pointer", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "40px" }}>
                    <i className="fa-thin fa-calculator" style={{ fontSize: "70px", color: "#726FA8" }}></i>
                    <div style={{ font: "normal normal 600 18px/22px Bio Sans", color: "#726FA8", marginTop: "10px", margin: "0px 10px" }}>CALCULATOR</div>
                </div>

                <div style={{ cursor:"pointer", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "40px" }}>
                    <i className="fa-thin fa-notes" style={{ fontSize: "70px", color: "#726FA8" }}></i>
                    <div style={{ font: "normal normal 600 18px/22px Bio Sans", color: "#726FA8", marginTop: "10px", margin: "0px 10px" }}>NOTES</div>
                </div>

                <div style={{ cursor:"pointer", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "40px" }}>
                    <i className="fa-thin fa-globe-pointer" style={{ fontSize: "70px", color: "#726FA8" }}></i>
                    <div style={{ font: "normal normal 600 18px/22px Bio Sans", color: "#726FA8", marginTop: "10px", margin: "0px 10px" }}>BROWSER</div>
                </div>

                <div style={{ cursor:"pointer", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "40px" }}>
                    <i className="fa-thin fa-coins" style={{ fontSize: "70px", color: "#726FA8" }}></i>
                    <div style={{ font: "normal normal 600 18px/22px Bio Sans", color: "#726FA8", marginTop: "10px", margin: "0px 10px" }}>CURRENCY</div>
                </div>

                <div style={{ cursor:"pointer", width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <i className="fa-thin fa-calendar-lines" style={{ fontSize: "70px", color: "#726FA8" }}></i>
                    <div style={{ font: "normal normal 600 18px/22px Bio Sans", color: "#726FA8", marginTop: "10px", margin: "0px 10px" }}>CALENDAR</div>
                </div>

            </div>

            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ cursor: "pointer", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "40px" }} onClick={() => SetWaitingForJoining(true)}>
                    <i className="fa-thin fa-pause" style={{ fontSize: "70px", color: "#726FA8" }}></i>
                    <div style={{ font: "normal normal 600 18px/22px Bio Sans", color: "#726FA8", marginTop: "10px", margin: "0px 10px" }}>PAUSE</div>
                </div>
                {currentConference?.topics[0].moderator._id == currentParticipant._id &&
                    <div style={{ cursor: "pointer", width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }} onClick={() => StopConference()}>
                        <i className="fa-thin fa-power-off" style={{ fontSize: "70px", color: "#726FA8" }}></i>

                        <div style={{ font: "normal normal 600 18px/22px Bio Sans", color: "#726FA8", marginTop: "10px", margin: "0px 10px", marginBottom:"20px" }}>CLOSE</div>
                    </div>
                }

            </div>

        </div>
    );
}

export default SideMenuComponent;