import { useRef, useContext, useState, useEffect } from 'react';
import styles from './DisplayRoomComponent.module.css'

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';
import { AddConferenceContext } from '../../../contexts/addConferenceContext';

import CornerImage from "../../../graphics/config/colt.png"
import DeskHImage from "../../../graphics/config/masa-h.png"
import DeskVImage from "../../../graphics/config/masa-v.png"
import remoteImage from '../../../graphics/Popups/img-remote-users.png'
import icMove from "../../../graphics/manage participants/ic-moove.svg"
import icZoom from "../../../graphics/manage participants/ic-zoom.svg"


const DisplayRoomComponent = ({displayedRoom, deskHeight, allRooms, isRemote, setIsRemote, changeRooms}) => {


    const {  } = useContext(GlobalContext);
    const { } = useContext(ProjectDetailsContext)
    const {  } = useContext(AddConferenceContext);

    useEffect(() => {

    },[])



    return (
        <>
        <div className={styles.roomDisplay}>
            {!isRemote && <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {
                            displayedRoom?.desks_north.length > 0 && displayedRoom?.desks_west.length > 0 && <><img alt={"img"} src={CornerImage} height={deskHeight} /></>
                        }
                        {displayedRoom?.desks_north.map((desk) => (
                            <div key={desk.number} style={{ position: "relative", display: "flex", justifyContent: "center"}}>
                                <span className={styles.deskNumber} style={{width: deskHeight / 2, height: deskHeight / 2, top: - deskHeight / 10 * 3}}>{desk.number}</span>
                                <img alt={"img"} src={DeskHImage} height={deskHeight} />
                            </div>
                        ))}
                        {
                            displayedRoom?.desks_north.length > 0 && displayedRoom?.desks_east.length > 0 && <img alt={"img"} src={CornerImage} height={deskHeight} style={{ transform: "rotate(90deg)" }} />
                        }
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "50%", display: "flex", justifyContent: "start" }}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                {displayedRoom?.desks_west.map((desk) => (
                                    <div key={desk.number} style={{ position: "relative" }}>
                                        <span className={styles.deskNumber} style={{ width: deskHeight / 2, height: deskHeight / 2, left: - deskHeight / 10 * 3 , top: "25%"}}>{desk.number}</span>
                                        <img alt={"img"} src={DeskVImage} width={deskHeight} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{ width: "50%", display: "flex", justifyContent: "end" }}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                {displayedRoom?.desks_east.map((desk) => (
                                    <div key={desk.number} style={{ position: "relative" }}>
                                        <img alt={"img"} src={DeskVImage} width={deskHeight} style={{ transform: "rotate(180deg)" }} />
                                        <span className={styles.deskNumber} style={{width: deskHeight / 2, height: deskHeight / 2, left: deskHeight / 10 * 7, top: "25%"}}>{desk.number}</span>
                                    </div>

                                ))}
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        {
                            displayedRoom?.desks_south.length > 0 && displayedRoom?.desks_west.length > 0 && <img alt={"img"} src={CornerImage} height={deskHeight} style={{ transform: "rotate(-90deg)" }} />
                        }
                        {displayedRoom?.desks_south.map((desk) => (
                            <div key={desk.number} style={{ position: "relative" }}>
                                <img alt={"img"} src={DeskHImage} height={deskHeight} style={{ transform: "rotate(180deg)" }} />
                                <span className={styles.deskNumber} style={{width: deskHeight / 2, height: deskHeight / 2, left: "25%", top: deskHeight / 10 * 7 }}>{desk.number}</span>
                            </div>
                        ))}
                        {
                            displayedRoom?.desks_south.length > 0 && displayedRoom?.desks_east.length > 0 && <img alt={"img"} src={CornerImage} height={deskHeight} style={{ transform: "rotate(180deg)" }} />
                        }
                    </div>
                </div>
            </div>}
            {isRemote && <img src={remoteImage} className={styles.remoteImage} alt={'remote'}/>}
            <div className={styles.moveIcon}>
                <img src={icMove} style={{width: 102}}/>
                Move
            </div>
            <div style={{right: 190}} className={styles.moveIcon}>
                <img src={icZoom}  style={{width: 72}}/>
                Zoom
            </div>
            
        </div>
        <div className={styles.roomsTab}>
            {allRooms?.map((room) => <div key={room._id} className={!isRemote && room._id === displayedRoom._id ? styles.roomCardSelected : styles.roomCard} onClick={() => changeRooms(room)}>
                <div style={{ fontSize: 28, lineHeight: 1, fontWeight: "normal", marginTop: "15px" }}>Bucharest</div>
                {room.name}
            </div>)}
            <div className={isRemote ? styles.roomCardSelected : styles.roomCard} style={{height: !isRemote ? 84 : 90, top: !isRemote ? 30 : 40}} onClick={() => setIsRemote(true)}>
                <div style={{  marginTop: 15 }}>Remote users</div>
            </div>
            
        </div>
        
        </>
    );

}

export default DisplayRoomComponent;