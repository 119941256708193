import React, { useRef, useContext, useEffect, useState } from 'react';
import styles from './PdfFileComponent.module.css'
import { GlobalContext } from "../contexts/globalContext"

import PdfIcon from '../graphics/conference/file-pdf.png'
import LoadingJson from '../graphics/json-animations/spinner-loading.json'

import Lottie from 'react-lottie-player'
import { MultitouchComponent, PdfComponent } from 'use-gesture-pack';

const PdfFileComponent = ({ file }) => {

    const ref = useRef(null);
    const { setSharedFiles, setOpenedFiles, appHeight, appWidth } = useContext(GlobalContext);

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const [isVisible, setIsVisible] = useState(false);

    const pdfRef = React.useRef();

    const containerStyle = {
        width: `${isVisible ? width : 60}px`,
        height: `${isVisible ? height : 75}px`,
        overflow: 'hidden',
        transition: `width 0.5s ease, height 0.5s ease, transform 0.5s ease`,
        transformOrigin: 'center center',
        transform: `translate(-50%, -50%) matrix(1, 0, 0, 1, 0, 0)`,
        borderRadius: "20px",
        padding: `${isVisible ? '20px' : '0px'}`,
        backdropFilter: `${isVisible ? 'blur(10px)' : ''}`,
        backgroundColor: `${isVisible ? 'rgba(0, 0, 0, 0.3)' : 'transparent'}`,
    };

    const onMinScaleReached = () => {
        setSharedFiles(files => files.filter(a => a.uid !== file.uid));
        setOpenedFiles(files => files.filter(a => a.uid !== file.uid));
    }

    const onSwipe = (direction) => {
        pdfRef.current.changePage(direction);
    }

    const onDocumentLoaded = (data) => {
        setIsVisible(true)
        setWidth(data.width);
        setHeight(data.height);
    }
    
  

    const options = {
        appWidth, 
        appHeight,
		maxScale: appHeight / 600,
        minScale: 0.5
	}

    return (
        <MultitouchComponent dragGesture={false} x={file.x + 70} y={file.y + 35} width={isVisible ? width : 60} height={isVisible ? height : 75} onSwipe={onSwipe} onMinScaleReached={onMinScaleReached} options={options}> 
            <div className={styles.touch_component} ref={ref} style={containerStyle}>
                <PdfComponent ref={pdfRef} src={process.env.REACT_APP_CMS_URL + file.path} onDocumentLoaded={onDocumentLoaded}>
                </PdfComponent>

                <Lottie
                    loop
                    animationData={LoadingJson}
                    play
                    speed={1.9}
                    style={{ position:"absolute", left:"-13px", top:"-13px", width: 85, height: 100, opacity: `${isVisible ? 0 : 1}`  }}
                />
                <img src={PdfIcon} style={{  position:"absolute", top:"0px", left:"0px", width: 60, height: 75, opacity: `${isVisible ? 0 : 1}`}}  alt="icon" draggable="false"  />

            </div>
        </MultitouchComponent>
    );
}

// .touch_component {
//     position: absolute;
//     will-change: transform;
//     cursor: grab;
//     touch-action: none;
//     user-select: none;
//     -webkit-user-select: none;

//     overflow: hidden;
//     overflow-y: none;
    
    
//     pointer-events: auto;
//     width: auto; 
//     height: auto;
//     /* background-color: transparent;    */
    
//     backdrop-filter: blur(10px);
//     -webkit-backdrop-filter: blur(10px);
//     background-color: rgba(0, 0, 0, 0.3);
// }

export default PdfFileComponent;