import { useState, useRef, createContext } from "react"
import en from "../langs/en.json"

export const GlobalContext = createContext([{}, () => {}])

export const GlobalProvider = ( props ) => {
    // general variables
    const socketRef = useRef();
    const conferenceSocketRef = useRef();


    const selectedTopicIdRef = useRef();
    const [language, setLanguage] = useState(en)
    const [usingInvitation, setUsingInvitation] = useState(false)
    const [currentProject, setCurrentProject] = useState()
    const [projectsList, setProjectsList] = useState()
    const [selectedProject, setSelectedProject] = useState()

    const [allUsers, setAllUsers] = useState()
    const [upcomingConferences, setUpcomingConferences] = useState([])
    const [notificationUpcomingConferences, setNotificationUpcomingConferences] = useState([])
    const [historyConferences, setHistoryConferences] = useState([])
    const [statusProjectList, setStatusProjectList] = useState(true) // active = true, inactive = false, all = null
    const [detailsUpcomingConference, setDetailsUpcomingConference] = useState()
    const [guestLogin, setGuestLogin] = useState()
    const [userLogin, setUserLogin] = useState()
    const [conferenceLogin, setConferenceLogin] = useState()
    const [seeUserDetails, setSeeUserDetails] = useState()
    const [topicFiles, setTopicFiles] = useState()
    const [selectedParentForFiles, setSelectedParentForFiles] = useState()
    const [selectedRefTypeForFiles, setSelectedRefTypeForFiles] = useState()
    
    const [selectedTopic, setSelectedTopic] = useState()
    const [createNewProject, setCreateNewProject] = useState(false)

    const [departmentList, setDepartmentList] = useState()
    const [functionList, setFunctionList] = useState()

    // login variables
    const [currentUser, setCurrentUser] = useState()

    const [deviceId, setDeviceId] = useState();
    const [deskData, setDeskData] = useState()
    const [roomDataForRegister, setRoomDataForRegister] = useState()

    const [appHeight, setAppHeight] = useState()
    const [appWidth, setAppWidth] = useState()

    //Conference variables
    const [currentConference, setCurrentConference] = useState()
    const [currentParticipant, setCurrentParticipant] = useState()
    const [currentTopic, setCurrentTopic] = useState()
    const [currentModeratorStream, setCurrentModeratorStream] = useState()
    const [currentParticipantStream, setCurrentParticipantStream] = useState()
    const [alertMessage, setAlertMessage] = useState()

    const [openedFiles, setOpenedFiles] = useState([])
    const [sharedFiles, setSharedFiles] = useState([])

    const [openNotif, setOpenNotif] = useState(false)
    const [seeNotification, setSeeNotification] = useState(false)
    

    
    return (
        <GlobalContext.Provider value={{
            socketRef, conferenceSocketRef,
            selectedTopicIdRef,
            appHeight, setAppHeight,
            appWidth, setAppWidth,
            language, setLanguage,
            currentUser, setCurrentUser,
            usingInvitation, setUsingInvitation,
            currentProject, setCurrentProject,
            projectsList, setProjectsList,
            selectedProject, setSelectedProject,
            allUsers, setAllUsers,
            upcomingConferences, setUpcomingConferences,
            notificationUpcomingConferences, setNotificationUpcomingConferences,
            historyConferences, setHistoryConferences,
            statusProjectList, setStatusProjectList,
            detailsUpcomingConference, setDetailsUpcomingConference,
            guestLogin, setGuestLogin,
            userLogin, setUserLogin,
            seeUserDetails, setSeeUserDetails,
            topicFiles, setTopicFiles,
            selectedParentForFiles, setSelectedParentForFiles,
            selectedRefTypeForFiles, setSelectedRefTypeForFiles,            
            currentConference, setCurrentConference,
            selectedTopic, setSelectedTopic,
            departmentList, setDepartmentList,
            functionList, setFunctionList,
            deviceId, setDeviceId,
            deskData, setDeskData,
            currentParticipant, setCurrentParticipant,
            roomDataForRegister, setRoomDataForRegister, 
            createNewProject, setCreateNewProject,
            currentTopic, setCurrentTopic,
            currentModeratorStream, setCurrentModeratorStream,
            currentParticipantStream, setCurrentParticipantStream,
            conferenceLogin, setConferenceLogin,
            alertMessage, setAlertMessage,
            openedFiles, setOpenedFiles,
            sharedFiles, setSharedFiles,
            openNotif, setOpenNotif,
            seeNotification, setSeeNotification
        }}>
            {props.children}
        </GlobalContext.Provider>
    )
}