import { useEffect, useRef, useState, useContext } from 'react';

import { GlobalContext } from "../../contexts/globalContext";
import { translate, compose, toString, identity, fromString } from 'transformation-matrix';
import styles from './TopicDetailsComponent.module.css'

import PdfIcon from '../../graphics/conference/file-pdf.png'
import DocIcon from '../../graphics/conference/file-docs.png'
import SheetIcon from '../../graphics/conference/file-sheet.png'
import VideoIcon from '../../graphics/conference/file-audio.png'

const TopicDetailsComponent = ({ topic }) => {

    const { setOpenedFiles, appHeight } = useContext(GlobalContext);
    // const [topicNumber, setTopicNumber] = useState("01");
    const [selectedSubtopic, setSelectedSubtopic] = useState();
    const subtopicListRef = useRef()

    useEffect(() => {
        subtopicListRef.current.scrollLeft = 0;
    }, [selectedSubtopic])


    const GetIcon = (docsFile) => {
        switch (docsFile.type) {
            case "doc":
                return DocIcon;
            case "sheet":
                return SheetIcon;
            case "image":
                return process.env.REACT_APP_CMS_URL + docsFile.path;
            case "video":
                return VideoIcon;
            case "pdf":
                return PdfIcon;
            default:
                return <i className="bi bi-file-earmark-text" style={{ fontSize: "70px" }}></i>;
        }
    }

    const OpenFile = (file) => {
        console.log("file", file);
        setOpenedFiles(files => [...files, { ui: file._id, x: 1500, y: appHeight / 2, type: file.docsFile.type, path: file.docsFile.path, scale: 1, rotation: 0 }])
    }

    return (
        <div style={{ position: "relative", padding: "0px", width: "900px", display: "flex", flexDirection: "column", overflow: "hidden", border: "0px" }}>
            <div onClick={() => setSelectedSubtopic(null)} style={{ height: `${selectedSubtopic ? 100 : 0}px`, transition: "height 0.5s", backgroundColor: "#1C1539", cursor: "pointer", color: "white", font: "normal normal 600 34px/36px 'Bio Sans'" }}><i className="fa-thin fa-chevron-left" style={{ opacity: `${selectedSubtopic ? 1 : 0}`, font: "normal normal 300 28px/35px", margin: "29px 20px 0px 40px" }}></i>{selectedSubtopic?.name}</div>
            <div ref={subtopicListRef} className={styles.subtopicsListScrollBar} style={{ backgroundColor: "#1C1539", width: "900px", display: "flex", flexDirection: "row", justifyContent: "start", padding: "0px" }}>
                {!selectedSubtopic &&
                    topic.subtopics.map((subtopic) => (
                        <div key={subtopic._id} onClick={() => setSelectedSubtopic(subtopic.files.length > 0 ? subtopic : null)} className={styles.subtopicElement} style={{ backgroundColor: "#292148", width: "470px", height: "250px", fontSize: "40px", margin: "20px 20px" }}>
                            <div className={styles.title}>{subtopic.name}</div>
                            <div className={styles.subtitle}>{subtopic.files.length > 0 ? subtopic.files.length : "No"} files</div>
                        </div>
                    ))
                }

                {!selectedSubtopic &&
                    topic.files.map((file) => (
                        <div onClick={() => OpenFile(file)} key={file._id} className={styles.fileElement} style={{ height: "250px", fontSize: "40px", margin: "20px 20px" }}>
                            <img src={GetIcon(file.docsFile)} height={220} />
                            <div className={styles.file_title} style={{ font: "normal normal 600 18px/20px Bio Sans" }}>{file.docsFile.name}</div>
                        </div>
                    ))
                }

                {selectedSubtopic &&
                    selectedSubtopic.files.map((file) => (
                        <div onClick={() => OpenFile(file)} key={file._id} className={styles.fileElement} style={{ height: "250px", fontSize: "40px", margin: "20px 20px" }}>
                            <img src={GetIcon(file.docsFile)} height={220} />
                            <div className={styles.file_title} style={{ font: "normal normal 600 18px/20px Bio Sans" }}>{file.docsFile.name}</div>
                        </div>
                    ))
                }

            </div>
            <div style={{ position: "absolute", width: "40px", height: "100%", background: "linear-gradient(90deg, #1C1539FF 0%, #1C153900 100%)", left: "0px", top: "0px", margin: "0" }}></div>
            <div style={{ position: "absolute", width: "40px", height: "100%", background: "linear-gradient(90deg, #1C153900 0%, #1C1539FF 100%)", right: "0px", top: "0px", margin: "0" }}></div>
        </div>
    );
}

export default TopicDetailsComponent;