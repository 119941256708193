import styles from './FooterButtonsComponent.module.css'

import LightButtonComponent from '../../commons/LightButtonComponent';
import DarkButtonComponent from '../../commons/DarkButtonComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';



const FooterButtonsComponent = ({lightText, lightClick, darkClick, isOpaque, style, lightWidth = 377, darkWidth = 377}) => {
    
    return (
        <div className={styles.footerButtons} style={style}>
            <LightButtonComponent onClick={lightClick} style={{
                opacity: isOpaque ? 0.2 : 1, 
                width: lightWidth, 
                height: 82, 
                marginLeft: 0, 
                font: "normal normal 600 40px/80px 'Bio Sans'",
                borderRadius: 8
            }}>
                <FontAwesomeIcon className={styles.iconPlus} icon={byPrefixAndName.fas['plus']} /> 
                {lightText}
            </LightButtonComponent>
            <DarkButtonComponent onClick={darkClick} style={{width: darkWidth, height: 78, marginLeft: 40, marginTop: 2,  backgroundColor: "transparent"}}>
                <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['xmark']} /> 
                Cancel
            </DarkButtonComponent>
        </div>
    );

}

export default FooterButtonsComponent;