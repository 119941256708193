import { useRef, useContext, useState } from 'react';
import styles from './NewProjectPopup.module.css'

import { GlobalContext } from "../../contexts/globalContext";
import { ProjectDetailsContext } from '../../contexts/projectDetailsContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import InputTextComponent from '../keyboard/InputTextComponent';
import KeyboardComponent from '../keyboard/KeyboardComponent';
import PopupBodyComponent from '../popup/common/PopupBodyComponent';
import PopupBaseComponent from '../popup/common/PopupBaseComponent';
import ClosePageButtonComponent from '../commons/ClosePageButtonComponent'
import RightBodyComponent from '../popup/common/RightBodyComponent';
import LeftBodyComponent from '../popup/common/LeftBodyComponent';

import membersAdd from '../../graphics/Popups/Icon Members Add.png'
import FooterButtonsComponent from '../popup/common/FooterButtonsComponent';
import OutsideGlowComponent from '../popup/common/OutsideGlowComponent';


const NewProjectPopup = () => {

    const inputRef = useRef()
    const keyboardRef = useRef()

    const [isOnFocus, setIsOnFocus] = useState(false)

    const {socketRef, setSelectedProject, setTopicFiles, currentUser, selectedTopicIdRef, setCreateNewProject, appWidth, appHeight } = useContext(GlobalContext);
    // const {  } = useContext(ProjectDetailsContext);


    function addProject() {
        socketRef.current.emit("UPDATE_PROJECT", { projectId: null, newData: { name: inputRef.current.value }, ownerId: currentUser._id },
            (id) => {
                socketRef.current.emit("GET_PROJECT_BY_ID", { projectId: id, selectedTopicId: selectedTopicIdRef.current }, (project, conference, topicFiles) => {
                    setSelectedProject(project)
                    setTopicFiles(topicFiles)
                    setCreateNewProject(false)

                    if (project.topics.length > 0 && !selectedTopicIdRef.current)
                        selectedTopicIdRef.current = project.topics[0]._id;
                })
            }
        );
    }

    
    return (
        <PopupBaseComponent>
            <KeyboardComponent visible ref={keyboardRef} x={(appWidth - 2140 - 60) / 2} y={appHeight * 2 / 3} width="2140px" height="610px" s="1" />

            <PopupBodyComponent style={{width: 2300, height: 942, top: "12%"}}>
                <ClosePageButtonComponent setClose={() => setCreateNewProject(false)} />
                <LeftBodyComponent image={membersAdd} title="New Project" description="Create a new project to organize essential details and documents. Manage conferences, meetings, users, and files—all in one place for seamless tracking."/>
                <RightBodyComponent>
                    <div className={styles.title}>Project title</div>
                    {isOnFocus && <OutsideGlowComponent style={{left: 1065, top: 492, width: 1300}}/>}
                    <InputTextComponent ref={inputRef} keyboardRef={keyboardRef} value="" placeHolder="Enter project title here" styleType='POPUP' width= "1260px" fontSize="40px" setIsOnFocus={setIsOnFocus} />
                    {!isOnFocus && <FontAwesomeIcon className={styles.iconEdit} icon={byPrefixAndName.fal['pen']} />}
                    <FooterButtonsComponent lightText={"Create"} lightClick={addProject} darkClick={setCreateNewProject}  isOpaque={inputRef.current?.value === "" && inputRef.current && !isOnFocus} style={{bottom: 388}}/>
                </RightBodyComponent>
            </PopupBodyComponent>
            
        </PopupBaseComponent>
    );

}

export default NewProjectPopup;