import { useEffect, useContext, useState } from 'react';

import { GlobalContext } from "../../contexts/globalContext";
import ModeratorVideoComponent from "./ModeratorVideoComponent";
import ConfHeaderComponent from './ConfHeaderComponent';
import TopicComponent from './TopicComponent';



const TopicsComponent = ({ SetWaitingForJoining }) => {

    const { currentConference, currentTopic, setCurrentTopic, currentParticipant, conferenceSocketRef, currentModeratorStream, currentParticipantStream } = useContext(GlobalContext)
    const [topics, setTopics] = useState([]);

    useEffect(() => {
        setTopics(currentConference.topics);
        var aux_topic = currentConference.topics.find(topic => topic._id == currentConference.currentTopic);
        console.log("setCurrentTopic 1");
        setCurrentTopic(aux_topic);

        conferenceSocketRef.current.on('UPDATE_CURRENT_TOPIC', (topicId) => {
            if (topicId) {
                var aux_topic = currentConference.topics.find(topic => topic._id == topicId);
                console.log("setCurrentTopic 2");
                setCurrentTopic(aux_topic);
            } else {
                //setCurrentModeratorStream(null);
                setCurrentTopic(null);
            }
        })

        conferenceSocketRef.current.on('DOCS_REFRESH_ACTIVE_CONFERENCE_TOPICS', (updatedTopic) => {
            console.log("updatedTopic",updatedTopic);
            setTopics(objs =>
                objs.map(item =>
                    item._id === updatedTopic._id ? updatedTopic : item
                )
            );
        })

        return () => {
            conferenceSocketRef.current?.off('UPDATE_CURRENT_TOPIC')
        }
    }, [])   

    return (
        <div style={{ position: "absolute", top: "20px", left: "165px" }}>
            
            <ConfHeaderComponent></ConfHeaderComponent>

            
            {currentModeratorStream && <ModeratorVideoComponent key={currentModeratorStream.remoteProducerId}></ModeratorVideoComponent>}


            {topics.map((topic, index) => (
                <TopicComponent key={topic._id} topic={topic} index={index + 1}></TopicComponent>                
            ))}
            
        </div>
    );
}

export default TopicsComponent;