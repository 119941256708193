import { useRef, useContext, useState, useEffect } from 'react';
import styles from './ConferenceAddVisitorComponent.module.css'

import { GlobalContext } from "../../../contexts/globalContext";
import { AddConferenceContext } from '../../../contexts/addConferenceContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import InputTextComponent from '../../keyboard/InputTextComponent';
import KeyboardComponent from '../../keyboard/KeyboardComponent';
import PopupHeaderComponent from '../common/PopupHeaderComponent';
import PopupBodyComponent from '../common/PopupBodyComponent';
import PopupBaseComponent from '../common/PopupBaseComponent';
import ClosePageButtonComponent from '../../commons/ClosePageButtonComponent'
import RightBodyComponent from '../common/RightBodyComponent';
import LeftBodyComponent from '../common/LeftBodyComponent';

import membersAdd from '../../../graphics/Popups/Icon Members Add.png'
import FooterButtonsComponent from '../common/FooterButtonsComponent';
import OutsideGlowComponent from '../common/OutsideGlowComponent';


const ConferenceAddVisitorComponent = ({setDisplayedParticipants}) => {

    const inputRefName = useRef()
    const inputRefEntitle = useRef()
    const keyboardRef = useRef()

    const [input, setInput] = useState('');
    const [output, setOutput] = useState('');

    const [isOnFocusName, setIsOnFocusName] = useState(false)
    const [isOnFocusEntitling, setIsOnFocusEntitling] = useState(false)

    const { selectedProject, socketRef } = useContext(GlobalContext);
    const { setAddVisitor } = useContext(AddConferenceContext);

 

    function handleEmailAdd(){
        socketRef.current.emit("CREATE_USER_AND_ADD_TO_PROJECT", { 
            projectId: selectedProject._id, 
            userData: {
                "name": inputRefName.current.value ? inputRefName.current.value : "Default Visitor Name", 
                "email": output,
                "role": "VISITOR",
            } 
        }, (callback) => {
            if(!callback.error){
                console.log(callback.response[0])
                setDisplayedParticipants((prev) => [...prev, callback.response[0]])
                setAddVisitor(false)
            } 
            
        })
        
    }

    const transformString = (str) => {
        return str.toLowerCase().replace(/\s+/g, '.');
    };

    useEffect(() => {setOutput(transformString(input))},[input])

    
    return (
        <PopupBaseComponent>
            <PopupBodyComponent style={{width: 2760, height: 800, top: "15%"}}>
                <KeyboardComponent visible ref={keyboardRef} x="330" y="1050" width="2082px" height="651px" s="1"/>
                <ClosePageButtonComponent setClose={() => setAddVisitor(false)} />
                <LeftBodyComponent image={membersAdd} title="Add visitor" description="Enter the email addresses of the guest users you wish to add to the project. These guests will receive access to project resources and notifications."/>
                <RightBodyComponent>
                    <div className={styles.title}>Name</div>
                    {isOnFocusName && <OutsideGlowComponent style={{top: 332, left: 1231.5}}/>}
                    <InputTextComponent ref={inputRefName} onTextChanged={() => setInput(inputRefName.current.value)} keyboardRef={keyboardRef} value="" placeHolder="Enter name here" styleType='POPUP' width= "1560px" fontSize="40px" setIsOnFocus={setIsOnFocusName}/>
                    {!isOnFocusName && <FontAwesomeIcon className={styles.iconEdit} icon={byPrefixAndName.fal['pen']} />}
                    <div className={styles.title} style={{marginTop: 50}}>Entitling</div>
                    {isOnFocusEntitling && <OutsideGlowComponent style={{top: 579, left: 1231.5}}/>}
                    <InputTextComponent ref={inputRefEntitle} onTextChanged={() => setInput(inputRefName.current.value)} keyboardRef={keyboardRef} value="" placeHolder="Enter entitling here" styleType='POPUP' width= "1560px" fontSize="40px" setIsOnFocus={setIsOnFocusEntitling}/>
                    {!isOnFocusEntitling && <FontAwesomeIcon className={styles.iconEdit2} icon={byPrefixAndName.fal['pen']} />}
                    <FooterButtonsComponent lightText={"Add"} lightClick={handleEmailAdd} isOpaque={inputRefName.current?.value === "" && !isOnFocusName } darkClick={setAddVisitor}/>
                
                </RightBodyComponent>
            </PopupBodyComponent>
            
        </PopupBaseComponent>
    );

}

export default ConferenceAddVisitorComponent;