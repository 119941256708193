import { useContext } from 'react';

import { GlobalConfigContext } from "../contexts/globalConfigContext";
import CFG_LoginComponent from './CFG_LoginComponent';
import CFG_MainScreenComponent from './CFG_MainScreenComponent';

function CFG_App() {

    const { currentUser } = useContext(GlobalConfigContext);

    return (
        <>
            {!currentUser && <CFG_LoginComponent></CFG_LoginComponent>}
            {currentUser && <CFG_MainScreenComponent/>}
        </>
    );    
}

export default CFG_App;
