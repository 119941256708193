import { useContext, useEffect, useRef, useState } from 'react';
import styles from './WaitingForJoiningScreen.module.css';
import { GlobalContext } from "../../contexts/globalContext";
import InputTextComponent from '../keyboard/InputTextComponent'
import arrowLeft from '../../graphics/arrow-left-animation.json';
import KeyboardComponent from '../keyboard/KeyboardComponent';
import Lottie from 'react-lottie-player';
import { ApiConferenceAttachNewVisitorToDesk } from '../../utils/RestServices';

const RegisterNewVisitor = () => {
    const { conferenceLogin, currentConference, deskData, setCurrentParticipant, setCurrentConference, appHeight, appWidth } = useContext(GlobalContext);
    const [localStream, setLocalStream] = useState(null);
    const [isNameCompleted, setIsNameCompleted] = useState(false);

    const inputVisitorNameRef = useRef();
    const inputEntitleNameRef = useRef();
    const videoRef = useRef();
    const keyboardRef = useRef();

    const handleNameChange = () => {
        console.log('here ajungi?')
        setIsNameCompleted(inputVisitorNameRef.current.value?.trim().length > 0);
    };

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({
            video: {
                width: {
                    min: 640,
                    max: 960,
                },
                height: {
                    min: 400,
                    max: 540,
                }
            }, audio: false
        })
            .then(stream => {
                setLocalStream(stream);
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
            })
            .catch(error => {
                console.error("Error accessing primary media devices.", error);
            });

        return () => {
            if (localStream) {
                localStream.getTracks().forEach(track => track.stop());
            }
        };
    }, []);

    const RegisterNewVisitor = () => {
        var body = JSON.stringify({
            deskId: deskData._id,
            conferenceId: currentConference._id,
            name: inputVisitorNameRef.current.value,
            entitle: inputEntitleNameRef.current.value ? inputEntitleNameRef.current.value : null

        });

        ApiConferenceAttachNewVisitorToDesk(body, data => {
            console.log('inregistrez un user', data);
            if (data.conference && data.participant) {

                setCurrentParticipant(data.participant);
                setCurrentConference(data.conference);
            };
        }
        );
    }

    return (
        <div className={styles.component} style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignSelf: 'center',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -61%)'
        }}>
            <div style={{ marginTop: 45, display: 'flex', gap: '20px', width: '1100px', height: '718px', flexWrap: 'wrap', justifyContent: 'center' }}>
                <div style={{ height: 700, overflow: "hidden", borderRadius: 20 }}>
                    <video
                        ref={videoRef}
                        autoPlay
                        muted
                        style={{ width: '100%', height: '1100px', marginTop: -200 }}
                    ></video>
                </div>
            </div>

            <label className={styles.welcomeText} style={{ marginTop: 122 }}>Welcome!</label>
            <label className={styles.nameAndEntitleText}>Name and entitling</label>
            <InputTextComponent
                ref={inputVisitorNameRef}
                keyboardRef={keyboardRef}
                value={conferenceLogin ? conferenceLogin.name : ""}
                fontSize="40px"
                placeHolder="Name"
                styleType='POPUP'
                width="1000px"
                height="120px"
                onChange={() => handleNameChange()}
                onTextChanged={handleNameChange}
            />
            <InputTextComponent
                ref={inputEntitleNameRef}
                keyboardRef={keyboardRef}
                value={conferenceLogin ? conferenceLogin.entitle : ""}
                fontSize="40px"
                placeHolder="Position / Role"
                styleType='POPUP'
                width="1000px"
                height="120px"
            />
            <div className={styles.confModeratorTextDiv} style={{ marginTop: 8 }}>
                <label className={styles.confModeratorText}>STARTING SOON</label>
            </div>
            <label className={styles.customText} style={{ marginTop: 28 }}> Press the button to confirm </label>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <button
                    className={styles.joinButton}
                    disabled={!isNameCompleted}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignContent: 'center',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        opacity: isNameCompleted ? 1 : 0.5,
                        cursor: isNameCompleted ? 'pointer' : 'not-allowed',
                    }}
                    onClick={RegisterNewVisitor}>
                    <Lottie
                        loop={true}
                        play={true}
                        animationData={arrowLeft}
                        style={{ position: "relative", width: 60, height: 35 }}
                    />
                    <label>Request to join</label>
                </button>
            </div>
            <KeyboardComponent visible ref={keyboardRef} x={0} y={1660} width="2140px" height="610px"  s="1" position="relative" />
        </div>
    );
}

export default RegisterNewVisitor;
