import { useEffect, useState } from 'react';
import styles from './CustomDropdownComponent.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons'
import { GetMaxZIndex } from '../../utils/UtilitaryFunctions';


const CustomDropdownComponent = ({ options, forConf, currentChoice, setCurrentChoice, width = 573, style, setOpenStatus, marginLeftText = 0 }) => {
    const [isOpen, setIsOpen] = useState(false)

    function getIcon(value) {
        switch (value) {
            case "1":
                return "arrow-up-a-z";
            case "2":
                return "arrow-down-z-a";
            case "3":
                return "arrow-up-wide-short";
            case "4":
                return "arrow-down-short-wide";
            case "5":
                return "arrow-up-1-9";
            default:
                return "arrow-down-9-1";
        }
    }

    useEffect(() => {
        if(setOpenStatus)
            setOpenStatus(isOpen)
    },[isOpen])

    return (
        <div className={styles.component} tabIndex="0" onBlur={() => setIsOpen(false)} style={style}>
            <div className={isOpen ? styles.currentChoiceOpen : styles.currentChoice} onClick={() => setIsOpen(!isOpen)} style={{width}}>
                <div style={{marginLeft: marginLeftText}}>{currentChoice.text}</div>
                <FontAwesomeIcon className={styles.dropdownIcon} icon={byPrefixAndName.fal[isOpen ? "chevron-up" : "chevron-down"]} />
            </div>

            {isOpen && <div className={styles.openBody} style={{width: width, zIndex: GetMaxZIndex() + 1}}>
                {options.map((option) => (
                    <div key={option.value} className={currentChoice.text === option.text ? styles.optionSelected : styles.option} onClick={() => {
                        setCurrentChoice(option)
                        setIsOpen(false)
                    }}>
                        {forConf && <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fal[getIcon(option.value)]} />}
                        <div style={{marginLeft: marginLeftText}}>{option.text}</div>
                    </div>
                ))}
            </div>}

        </div>

    );

}

export default CustomDropdownComponent;