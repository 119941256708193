
import { useContext, useEffect, useRef, useState } from 'react';
import styles from './PD_ConferenceComponent.module.css'
import { ProjectDetailsContext } from "../../../contexts/projectDetailsContext";
import { GlobalContext } from "../../../contexts/globalContext";

import MoreButtonComponent from '../../commons/MoreButtonComponent';

import TagComponent from '../../projectList/TagComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';
import DarkButtonComponent from '../../commons/DarkButtonComponent';
import LightButtonComponent from '../../commons/LightButtonComponent';

import DetailCountComponent from '../../projectList/DetailCountComponent';
import DeleteNotificationComponent from '../../popup/common/DeleteNotificationComponent';


const PD_ConferenceComponent = ({ conference }) => {
    const moreButtonRef = useRef()

    const { socketRef, currentUser, selectedProject, language, detailsUpcomingConference, setConferenceScreen, setCurrentConference } = useContext(GlobalContext);

    const { setEditConference, moreClicked, setMoreClicked } = useContext(ProjectDetailsContext);

    const date = new Date(conference.scheduleDate)
    const [isDelete, setIsDelete] = useState(false)

    function deleteConference() {
        socketRef.current.emit("DELETE_CONFERENCE", conference._id, selectedProject._id, () => setMoreClicked({}))
    }

    function selectConference() {
        socketRef.current.emit("GET_CONFERENCE_BY_ID", conference._id, (callback) => {
            setEditConference(callback.response)
        })
    }

    function handleClick() {
        if (moreClicked.shown && conference._id === moreClicked.id) {
            setMoreClicked({})
        } else {
            setMoreClicked({
                ...moreClicked,
                shown: true,
                id: conference._id,
                clickRemove: () => setIsDelete(true),
                clickEdit: () => selectConference(),
                x: moreButtonRef.current.getBoundingClientRect().x,
                y: moreButtonRef.current.getBoundingClientRect().y
            })
        }
    }

    return (
        <div className={new Date(conference.scheduleDate) < new Date() ? styles.pastElement : styles.element}
            style={{
                background: conference.type === "Conference" ? "" : (new Date(conference.scheduleDate) >= new Date() ? "#2D2748 0% 0% no-repeat padding-box" : "#231A3E80 0% 0% no-repeat padding-box"),
                borderColor: conference.type === "Conference" ? "#6CEA7F" : "#E56ADB",
                borderWidth: detailsUpcomingConference?._id === conference._id ? "2px" : "0px",
            }}>
            <div className={styles.info}>
                <div>
                    <div className={styles.title_tags}>
                        <TagComponent status={conference.type} text={conference.type} />
                        {new Date(conference.scheduleDate) > new Date() && detailsUpcomingConference?._id !== conference._id &&
                            <TagComponent status="active" text="Future" />}
                        {new Date(conference.scheduleDate) < new Date() &&
                            <TagComponent status="inactive" text="Past" />}
                        {detailsUpcomingConference?._id === conference._id &&
                            <TagComponent status="live" text="Upcoming" />}
                    </div>
                    <div style={{ marginTop: 10 }}>{conference.name}</div>
                </div>
                <MoreButtonComponent ref={moreButtonRef} clicked={moreClicked} setClicked={handleClick} id={conference._id} />
            </div>
            <div className={styles.details}>
                <div className={styles.half_detail} style={{ borderRight: "2px solid #FFFFFF33" }}>
                    <div style={{ color: "#CDCCD2" }}>
                        <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fal['calendar-days']} />
                        Date
                    </div>
                    <div className={styles.detail_accent}>
                        {date.toLocaleDateString('en-RO', { weekday: 'short' })} {date.toLocaleDateString('en-RO', { day: '2-digit' })} {date.toLocaleDateString('en-RO', { month: 'short' })}
                    </div>
                </div>
                <div className={styles.half_detail} >
                    <div style={{ color: "#CDCCD2" }}>
                        <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fal['clock']} />
                        Starting at
                    </div>
                    <div className={styles.detail_accent}>
                        {date.toLocaleTimeString('en-RO', { hour12: false, hour: '2-digit', minute: '2-digit' })}
                    </div>
                </div>
            </div>
            <div className={styles.moreInfo} style={{ color: "#CDCCD2" }}>
                <DetailCountComponent project={conference} type={"nrTopics"} style={{ font: "normal normal normal 28px/42px 'Bio Sans'", opacity: new Date(conference.scheduleDate) < new Date() ? 0.5 : 1 }} />
                <DetailCountComponent project={conference} type={"nrAttachedParticipants"} style={{ font: "normal normal normal 28px/42px 'Bio Sans'", opacity: new Date(conference.scheduleDate) < new Date() ? 0.5 : 1 }} />
                <DetailCountComponent project={conference} type={"nrModerators"} style={{ font: "normal normal normal 28px/42px 'Bio Sans'", opacity: new Date(conference.scheduleDate) < new Date() ? 0.5 : 1 }} />
            </div>
            {new Date(conference.scheduleDate) > new Date() && detailsUpcomingConference?._id === conference._id && <div className={styles.buttons}>
                {detailsUpcomingConference?._id === conference._id && <LightButtonComponent onClick={() => { setConferenceScreen(true); setCurrentConference(conference._id) }}
                    style={{ font: "normal normal 600 40px/80px 'Bio Sans'", marginLeft: 0 }}>
                    <FontAwesomeIcon className={styles.iconArrow} icon={byPrefixAndName.fas['arrow-right']} />
                    {currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator" ? "START" : "JOIN"}
                </LightButtonComponent>}
            </div>}
            { isDelete && <DeleteNotificationComponent name={'Conference'} funct={deleteConference} onClose={() => setIsDelete(false)} /> }
        </div>
    );

}



export default PD_ConferenceComponent;