import { useRef, useContext, useEffect, useState } from 'react';

import { GlobalContext } from "../../../contexts/globalContext"
import { ProjectDetailsContext } from "../../../contexts/projectDetailsContext";
import { AddConferenceContext } from "../../../contexts/addConferenceContext"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import styles from './FilesDetailsConferenceDetailsComponent.module.css'

import DetailsCardComponent from './DetailsCardComponent';
import DarkButtonComponent from '../../commons/DarkButtonComponent'

import imgAdd from '../../../graphics/Popups/Icon Files Add.png'



const FilesDetailsConferenceDetailsComponent = () => {

    const { socketRef, appHeight, currentUser } = useContext(GlobalContext)
    const {  } = useContext(ProjectDetailsContext)
    const { fileList, setFileList, openFiles, setConferenceAddFiles } = useContext(AddConferenceContext)

    const icon = (type) => {
        if (type.includes("image")) {
            return "file-image"
        } else if (type.includes("video")) {
            return "file-video"
        } else if (type.includes("pdf")) {
            return "file-pdf"
        } else if (type.includes("word")) {
            return "file-word"
        } else if (type.includes("excel")) {
            return "file-excel"
        } else {
            return "file-lines"
        }
    };


    useEffect(() => {
        let type = "Subtopic"
        if(openFiles.moderator){
            type = "Topic"
        }
        socketRef.current.emit("CONFERENCE_GET_TOPIC_ATTACHED_DOCUMENTS", { 
            parentId: openFiles._id, 
            type 
        }, callback => {
            // console.log(callback.response)
            if(!callback.error){
                setFileList(callback.response)
            }
            
        })
    },[openFiles])


    return (
        <DetailsCardComponent title="Files" height={appHeight - 483}>
            <div style={{width: "100%"}}>
                <div className={styles.openFiles}>
                    
                     <div className={styles.noFiles} style={{justifyContent: fileList.length === 0 ? "center" : "", alignItems: fileList.length === 0 ? "center" : ""}}>
                        {fileList.length === 0 &&<>
                            <img src={imgAdd} alt="icon add" className={styles.imgAdd} onClick={() => setConferenceAddFiles(true)}/>
                            <div className={styles.description}>Add files to topic</div>
                            {openFiles.name}
                        </>}
                        {fileList.length > 0 && fileList.map((file, index) => <div key={index} className={styles.fileCard}>
                            <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas[icon(file.docsFile.type)]} />
                            <div className={styles.fileDetails}>
                                {file.docsFile.name}
                                <div className={styles.fileDescription}>
                                    Added by:
                                    <div className={styles.fileAddedBy}>{file.addedBy?.name}</div>
                                </div>
                            </div>
                            
                        </div>)}
                    </div>
                    <DarkButtonComponent 
                        style={{width: 1056, backgroundColor: "transparent", bottom: 0, position: "relative", marginTop: 43}} onClick={() => setConferenceAddFiles(true)}>
                        <FontAwesomeIcon className={styles.iconPlus} icon={byPrefixAndName.fas['plus']} /> Add files
                    </DarkButtonComponent>
                </div>
            </div>
        </DetailsCardComponent>
    );

}

export default FilesDetailsConferenceDetailsComponent;