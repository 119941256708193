import { useContext, useState, useRef, useEffect } from 'react';
import { GlobalConfigContext } from "../../contexts/globalConfigContext";

import "./pages.css";

const CFG_AdminsPage = () => {
    const { configSocketRef, setCurrentError } = useContext(GlobalConfigContext);
    const [entities, setEntities] = useState([])
    const [functions, setFunctions] = useState([])
    const [departments, setDepartments] = useState([])

    const [selectedEntity, setSelectedEntity] = useState(null);
    const entityName = "Admin";

    const inputNameRef = useRef();
    const inputEmailRef = useRef();
    const inputFunctionRef = useRef();
    const inputDepartmentRef = useRef();
    const inputPasswordRef = useRef();

    useEffect(() => {
        if (selectedEntity) {
            inputNameRef.current.value = selectedEntity.name;
            inputEmailRef.current.value = selectedEntity.email;
            inputPasswordRef.current.value = null;

            inputDepartmentRef.current.value = selectedEntity.department._id;
            inputFunctionRef.current.value = selectedEntity.function._id;
        } else {
            inputNameRef.current.value = null;
            inputEmailRef.current.value = null;
            inputPasswordRef.current.value = null;
            inputDepartmentRef.current.value = "";
            inputFunctionRef.current.value = "";
        }
    }, [selectedEntity]);

    useEffect(() => {
        configSocketRef.current.emit("CONFIG_ALL_ADMINS", (admins) => {
            setEntities(admins)
        });

        configSocketRef.current.emit("CONFIG_ALL_FUNCTIONS", (functions) => {
            setFunctions(functions)
        });

        configSocketRef.current.emit("CONFIG_ALL_DEPARTMENTS", (functions) => {
            setDepartments(functions)
        });
    }, []);

    const addEntity = () => {

        if (selectedEntity) {
            if (inputNameRef.current.value && inputEmailRef.current.value && inputDepartmentRef.current.value && inputFunctionRef.current.value) {
                configSocketRef.current.emit("CONFIG_ADD_ADMIN", { id: selectedEntity._id, name: inputNameRef.current.value, email: inputEmailRef.current.value, departmentId: inputDepartmentRef.current.value, functionId: inputFunctionRef.current.value, password: inputPasswordRef.current.value ? inputPasswordRef.current.value : null }, (admins) => {
                    setEntities(admins)
                });
            }
        } else {
            if (inputNameRef.current.value && inputEmailRef.current.value && inputDepartmentRef.current.value && inputFunctionRef.current.value && inputPasswordRef.current.value) {
                configSocketRef.current.emit("CONFIG_ADD_ADMIN", { name: inputNameRef.current.value, email: inputEmailRef.current.value, departmentId: inputDepartmentRef.current.value, functionId: inputFunctionRef.current.value, password: inputPasswordRef.current.value }, (admins) => {
                    setEntities(admins);

                    inputNameRef.current.value = null;
                    inputEmailRef.current.value = null;
                    inputPasswordRef.current.value = null;
                    inputDepartmentRef.current.value = "";
                    inputFunctionRef.current.value = "";
                });
            }
        }

        setSelectedEntity(null);
    }

    const deleteEntity = () => {
        configSocketRef.current.emit("CONFIG_ARCHIVE_ADMIN", selectedEntity._id, (admins) => {
            if (typeof admins === 'string') {
                setCurrentError(admins);
            } else {
                setEntities(admins);
            }
        });
        setSelectedEntity(null);
    }

    return (
        <>
            <div className="pagetitle">
                <h1>{entityName}s</h1>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-lg-12">

                        <div className="card">
                            <div className="card-body">

                                <table className="table datatable" id="MyTable">
                                    <thead>
                                        <tr>
                                            <th>
                                                {entityName} Name
                                            </th>
                                            <th>
                                                Email
                                            </th>
                                            <th>
                                                Department
                                            </th>
                                            <th>
                                                Function
                                            </th>
                                            <th>

                                            </th>
                                            <th>

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {entities.map((entity) => (
                                            <tr key={entity._id} className='trx'>
                                                <td>{entity.name}</td>
                                                <td>{entity.email}</td>
                                                <td>{entity.department.name}</td>
                                                <td>{entity.function.name}</td>
                                                <td style={{ width: "40px" }}>
                                                    <a id="editButton" className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#addEntityModal" onClick={() => setSelectedEntity(entity)}><i className="bi bi-vector-pen"></i></a>
                                                </td>
                                                <td style={{ width: "40px" }}>
                                                    <a id="deleteButton" className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#deleteEntityModal" onClick={() => setSelectedEntity(entity)} ><i className="bi bi-trash"></i></a>
                                                </td>

                                            </tr>
                                        ))}

{entities.map((entity) => (
                                            <tr key={entity._id} className='trx'>
                                                <td>{entity.name}</td>
                                                <td>{entity.email}</td>
                                                <td>{entity.department.name}</td>
                                                <td>{entity.function.name}</td>
                                                <td style={{ width: "40px" }}>
                                                    <a id="editButton" className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#addEntityModal" onClick={() => setSelectedEntity(entity)}><i className="bi bi-vector-pen"></i></a>
                                                </td>
                                                <td style={{ width: "40px" }}>
                                                    <a id="deleteButton" className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#deleteEntityModal" onClick={() => setSelectedEntity(entity)} ><i className="bi bi-trash"></i></a>
                                                </td>

                                            </tr>
                                        ))}

{entities.map((entity) => (
                                            <tr key={entity._id} className='trx'>
                                                <td>{entity.name}</td>
                                                <td>{entity.email}</td>
                                                <td>{entity.department.name}</td>
                                                <td>{entity.function.name}</td>
                                                <td style={{ width: "40px" }}>
                                                    <a id="editButton" className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#addEntityModal" onClick={() => setSelectedEntity(entity)}><i className="bi bi-vector-pen"></i></a>
                                                </td>
                                                <td style={{ width: "40px" }}>
                                                    <a id="deleteButton" className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#deleteEntityModal" onClick={() => setSelectedEntity(entity)} ><i className="bi bi-trash"></i></a>
                                                </td>

                                            </tr>
                                        ))}

{entities.map((entity) => (
                                            <tr key={entity._id} className='trx'>
                                                <td>{entity.name}</td>
                                                <td>{entity.email}</td>
                                                <td>{entity.department.name}</td>
                                                <td>{entity.function.name}</td>
                                                <td style={{ width: "40px" }}>
                                                    <a id="editButton" className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#addEntityModal" onClick={() => setSelectedEntity(entity)}><i className="bi bi-vector-pen"></i></a>
                                                </td>
                                                <td style={{ width: "40px" }}>
                                                    <a id="deleteButton" className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#deleteEntityModal" onClick={() => setSelectedEntity(entity)} ><i className="bi bi-trash"></i></a>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <div style={{ position: 'fixed', bottom: "20px", right: "20px", width: "40px", height: "40px", fontSize: "30px", borderRadius: "40px", boxShadow: "0px 2px 10px #00000080" }} >
                <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#addEntityModal">
                    <i className="bi bi-plus" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}></i>
                </a>
            </div>

            <div className="modal fade" id="addEntityModal" tabIndex="-2">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{selectedEntity ? "Edit" : "Add"} {entityName}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setSelectedEntity(null)}></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-floating mb-3">
                                <input ref={inputNameRef} type="text" className="form-control" placeholder="Name" />
                                <label>Name</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input ref={inputEmailRef} type="text" className="form-control" placeholder="Email" />
                                <label>Email</label>
                            </div>

                            <div className="form-floating mb-3">
                                <input ref={inputPasswordRef} type="password" className="form-control" placeholder="Password" />
                                <label>Password</label>
                            </div>

                            <div class="form-floating mb-3">
                                <select ref={inputDepartmentRef} class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                    <option value="" selected="">Select department</option>
                                    {departments.map((department) => (
                                        <option value={department._id}>{department.name}</option>
                                    ))}
                                </select>
                                <label for="floatingSelect">Department</label>
                            </div>

                            <div class="form-floating mb-3">
                                <select ref={inputFunctionRef} class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                    <option value="" selected="">Select function</option>
                                    {functions.map((functionx) => (
                                        <option value={functionx._id}>{functionx.name}</option>
                                    ))}
                                </select>
                                <label for="floatingSelect">Function</label>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setSelectedEntity(null)}>Close</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => addEntity()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="deleteEntityModal" tabIndex="-2">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete {entityName}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setSelectedEntity(null)}></button>
                        </div>
                        <div className="modal-body">
                            Are you sure you want to archive <b>{selectedEntity?.name}</b> ?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setSelectedEntity(null)}>Close</button>
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => deleteEntity()}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default CFG_AdminsPage;
