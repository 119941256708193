import { useContext, useEffect, useRef, useState } from 'react';
import styles from './WaitingForJoiningScreen.module.css';
import { GlobalContext } from "../../contexts/globalContext";
import microfon from '../../graphics/conference/ic-microfon-moderator.svg';
import Lottie from 'react-lottie-player';
import waitingAnim from '../../graphics/conference/wait-start-anim.json';

const WaitingForJoiningScreen = ({ SetWaitingForJoining }) => {
    const { currentParticipant, currentConference, conferenceSocketRef, setCurrentConference } = useContext(GlobalContext);
    const [showStartConferenceForm, setShowStartConferenceForm] = useState(false);

    const [showJoinButton, setShowJoinButton] = useState(false);
    const [localStream, setLocalStream] = useState(null);

    const inputRefs = useRef([]);
    const videoRef = useRef();

    const [activeIndex, setActiveIndex] = useState(0);
    const [digitValues, setDigitValues] = useState(['', '', '', '']);

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({
            video: {
                width: {
                    min: 640,
                    max: 960,
                    //max: 1920,
                },
                height: {
                    min: 400,
                    max: 540,
                    //max: 1080,
                }
            }, audio: false
        })
            .then(stream => {
                setLocalStream(stream);
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
            })
            .catch(error => {
                console.error("Error accessing primary media devices.", error);
            });

        return () => {
            if (localStream) {
                localStream.getTracks().forEach(track => track.stop());
            }
        };
    }, []);

    useEffect(() => {
        if (currentParticipant) {
            conferenceSocketRef.current.on('CONFERENCE_STARTED', async (conference) => {
                console.log("CONFERENCE_STARTED", conference);
                setCurrentConference(conference);
                setShowJoinButton(true);
            });

            if (currentConference.status === "New") {
                if (currentConference.topics[0].moderator._id === currentParticipant._id) {
                    setShowStartConferenceForm(true);
                }
            } else {
                setShowJoinButton(true);
            }
            return () => {
                conferenceSocketRef.current.off('CONFERENCE_STARTED');
            };
        }
    }, []);

    useEffect(() => {
        if (activeIndex < digitValues.length) {
            inputRefs.current[activeIndex]?.focus();
        }
    }, [activeIndex, digitValues]);

    useEffect(() => {
        if (digitValues.every(digit => digit === '')) {
            setActiveIndex(0);
            inputRefs.current[0]?.focus();
        } else if (activeIndex >= 0 && activeIndex < digitValues.length) {
            inputRefs.current[activeIndex]?.focus();
        }
    }, [activeIndex, digitValues]);


    const StartConference = () => {
        const code = digitValues.join('');
        console.log(code)

        conferenceSocketRef.current.emit("START_CONFERENCE", currentConference._id, code, (data) => {
            if (data.error) {
                console.log(data.response);
            } else {
                setShowStartConferenceForm(false);
                SetWaitingForJoining(false);
            }
        });
    };

    const handleFocus = (index) => {
        setActiveIndex(index);
        inputRefs.current[index]?.focus();
    };

    const handleBlur = () => {
        setActiveIndex(-1);
    };

    const handleKeyDown = (index, event) => {
        const updatedDigits = [...digitValues];

        if (event.key === 'Backspace') {
            if (updatedDigits[index] !== '') {
                updatedDigits[index] = '';
                setDigitValues(updatedDigits);
                setActiveIndex(index);
            } else if (index > 0) {
                setActiveIndex(index - 1);
                inputRefs.current[index - 1]?.focus();
                updatedDigits[index - 1] = '';
                setDigitValues(updatedDigits);
            }
        }
    };

    const handleChange = (index, event) => {
        console.log('here')
        const { value } = event.target;
        const updatedDigits = [...digitValues];

        if (value.length <= 1) {
            updatedDigits[index] = value;
            setDigitValues(updatedDigits);

            if (value !== '' && index < digitValues.length - 1) {
                setActiveIndex(index + 1);
                inputRefs.current[index + 1]?.focus();
            }
        }
    };

    const handleKeyboardClick = (value) => {
        const updatedDigits = [...digitValues];

        if (value === 'x') {
            const allEmpty = updatedDigits.every(digit => digit === '');
            if (allEmpty) return; // Do nothing if all digits are empty

            const lastFilledIndex = updatedDigits.findIndex(digit => digit === '');
            const deleteIndex = lastFilledIndex === -1 ? updatedDigits.length - 1 : lastFilledIndex - 1;

            if (updatedDigits[deleteIndex] !== '') {
                updatedDigits[deleteIndex] = '';
                setDigitValues(updatedDigits);
                setActiveIndex(deleteIndex);
            } else if (deleteIndex > 0) {
                setActiveIndex(deleteIndex - 1);
                inputRefs.current[deleteIndex - 1]?.focus();
                updatedDigits[deleteIndex - 1] = '';
                setDigitValues(updatedDigits);
            }
        } else {
            const emptyIndex = updatedDigits.findIndex(digit => digit === '');
            if (emptyIndex !== -1) {
                updatedDigits[emptyIndex] = value;
                setDigitValues(updatedDigits);

                const nextIndex = emptyIndex + 1 < updatedDigits.length ? emptyIndex + 1 : emptyIndex;
                setActiveIndex(nextIndex);
                inputRefs.current[nextIndex]?.focus();
            }
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
            {/* {showJoinButton && (
                <div className={styles.activeConferenceNameDiv}>
                    <label className={styles.todayConf}>Today’s conference</label>
                    <label className={styles.nameTodaysConf}>{currentConference.name}</label>
                </div>
            )} */}
            <div className={styles.component} style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flexWrap: ""
            }}>
                <div style={{ marginTop: 45, display: 'flex', gap: '20px', width: '1100px', height: '718px', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <div style={{height: 700, overflow:"hidden", borderRadius: 20}}>
                        <video
                            ref={videoRef}
                            autoPlay
                            muted
                            style={{ width: '100%', height: '1100px', marginTop: -200 }}
                        ></video>
                    </div>
                </div>

                {showStartConferenceForm && <img src={microfon} className={styles.customElement} />}

                <label className={styles.welcomeText} style={{ marginTop: 54 }}>Welcome!</label>

                <label className={styles.nameText}>{currentParticipant.name}</label>

                {showStartConferenceForm && (
                    <div className={styles.bottomButtons} style={{ bottom: 89 }}>
                        <div className={styles.confModeratorTextDiv}>
                            <label className={styles.confModeratorText}>CONFERENCE MODERATOR</label>
                        </div>
                        <label className={styles.codeText}>Please enter the 4-digit code:</label>
                        <div className={styles.digitsDiv}>
                            {digitValues.map((digit, index) => (
                                <div
                                    key={index}
                                    className={`${styles.inputDigitFocused} 
                        ${index === 0 ? styles.first : ''} 
                        ${activeIndex === index ? styles.active : ''} 
                        ${digit !== '' ? styles.completedValue : ''}`}
                                    onClick={() => handleFocus(index)}
                                >
                                    <input
                                        ref={(el) => (inputRefs.current[index] = el)}
                                        className={styles.inputDigit}
                                        type="text"
                                        maxLength="1"
                                        value={digit}
                                        onFocus={() => handleFocus(index)}
                                        onBlur={handleBlur}
                                        onChange={(e) => handleChange(index, e)}
                                        onKeyDown={(e) =>


                                            handleKeyDown(index, e)}
                                    />
                                    {digit !== '' && (
                                        <span className={styles.completedLabel}>*</span>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className={styles.startButton} onClick={() => StartConference()}>
                            Start the Conference
                        </div>
                    </div>
                )}

                {(!showStartConferenceForm && !showJoinButton) && (
                    <div className={styles.bottomButtons} style={{ bottom: 146 }}>
                        <Lottie
                            loop={true}
                            play={true}
                            animationData={waitingAnim}
                            style={{ width: 320, height: 160, marginTop: 98 }}
                        />

                        <div className={styles.confModeratorTextDiv} style={{
                            marginTop: 56,
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            alignContent: 'center'
                        }}>
                            <label className={styles.confModeratorText}>STARTING SOON</label>
                        </div>

                        <label className={styles.customText}> Thank you for your patience </label>

                        <label className={styles.customTextSmall}>The moderator will start the conference shortly</label>

                    </div>
                )}

                {showStartConferenceForm && (
                    <div className={styles.digitsKeyboardElement}>
                        {['1', '2', '3', '4', '5', '6', '7', '8', '9', '', '0', 'x'].map((key, index) => (
                            <div
                                key={index}
                                className={styles.digitkeyboard}
                                onClick={() => handleKeyboardClick(key)}
                                style={{opacity: key==='' ? 0 : 1}}
                            >
                                <label className={styles.digitTextKeyboard}>{key == 'x' ?
                                    <i className="fa-thin fa-delete-left" style={{
                                        bottom: '50%',
                                        transform: 'translate(-25%, 50%)', position: "relative"
                                    }}></i> : key}</label>
                            </div>
                        ))}
                    </div>
                )}

                {showJoinButton && (
                    <div className={styles.bottomButtons}>
                        <div className={styles.confModeratorTextDiv} style={{
                            width: 194,
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            alignContent: 'center'
                        }}>
                            <label className={styles.confModeratorText}>IN PROGRESS</label>
                        </div>
                        <label className={styles.customText}> Press the button to enter </label>
                        <div>
                            <button type="text" onClick={() => SetWaitingForJoining(false)} className={styles.joinButton}>
                                Join Conference</button>
                        </div>
                    </div>
                )}
            </div>
            // {/* {showJoinButton && (
            //     <div className={styles.activeConferenceNameDiv} style={{textAlign: 'left'}}>
            //         <label className={styles.todayConf} style={{textAlign: 'left'}}>Main Topics</label>
            //        <div></div>
            //     </div>
            // )} */}
        </div>
    );
}

export default WaitingForJoiningScreen;
